
let obj = {}
obj.hk = {
    auth: {
        back: "反面",
        finish: "已認證",
        front: "正面",
        id_number: "護照/證件號",
        input_id_num: "請輸入護照/證件號",
        input_info: "請完善個人訊息",
        input_name: "請輸入您的姓名",
        name: "姓名",
        upload_back: "上傳護照/證件反面",
        upload_front: "上傳護照/證件正面",
        upload_idcard: "請上傳護照/證件的正反面",
        waiting: "審核中",
        reject: "拒絕",
        auth_again: "再次認證"
    },
    bank: {
        SWIFT: "SWIFT",
        account: "收款人帳戶",
        address: "銀行地址",
        bank_name: "分店名稱",
        coin_type: "收款貨幣",
        name: "銀行名稱",
        remark: "備註",
        swift_text: "SWIFT",
        username: "收款人"
    },
    financial: {
        bank: "銀行卡",
        bank_charge: "電匯",
        charge_amount: "充幣數量",
        charge_coin: "充幣",
        charge_history: "入金記錄",
        charge_num: "充值金額",
        coin: "數字貨幣",
        collect_coin_type: "收款貨幣",
        copy_address: "複製地址",
        financial_records: "財務記錄",
        input_charge_amount: "请输入充幣數量",
        input_charge_num: "请输入充值金額",
        isfinish: "已完成",
        isreject: "已拒絕",
        link_charge: "鏈上充值",
        select_network: "選擇網絡",
        uncheck: "未審核",
        unuseful: "無效",
        wallet_address: "錢包地址"
    },
    forgot: {
        input_sms_code: "請輸入驗證碼",
        reset: "重置密碼",
        send: "發送",
        sms_code: "驗證碼"
    },
    func: {
        billing_detail: "帳單詳情",
        charge_coin: "入金",
        invite_friend: "邀請好友",
        lang: "語言",
        real_auth: "實名認證",
        reset_password: "修改密碼",
        setting: "設置",
        wallet: "錢包",
        withdraw_coin: "出金"
    },
    order: {
        all_caution_money: "當前保證金",
        balance: "餘額",
        bond_fee: "保證金",
        buy_in: "買入",
        calc_bond_fee: "預估保證金",
        calc_handle_fee: "預估手續費",
        can_use: "可用預付款",
        cancel: "撤單",
        close: "平倉",
        close_price: "平倉價",
        confrim_close_order: "是否平倉",
        currency: "交易對",
        current_price: "當前價",
        dealing: "掛單",
        detail: "訂單詳情",
        hand_num: "手數",
        handle_fee: "手續費",
        hazard_rate: "風險率",
        history: "歷史",
        holding: "持倉",
        income: "收益",
        market_price: "市價",
        open_time: "開倉時間",
        order_id: "訂單號",
        order_price: "下單價",
        order_type: "方向",
        page: "手",
        pay_rate: "預付款比",
        profit: "盈虧",
        sell_out: "賣出",
        set_stop_price: "修改止盈止損",
        stop_loss: "止損",
        stop_profit: "止盈",
        times: "倍數",
        total: "淨值",
        total_ol: "總盈虧",
        trade_amount: "買入數量",
        trading: "開倉",
        un_deal_order: "掛單",
        unit: "每手"
    },
    public: {
        tocust: "立即聯系",
        add: "添加",
        cancel: "取消",
        chat: "聯繫客服",
        coming_soon: "敬請期待~",
        confirm: "確認",
        confirm_del: "確定刪除嗎？",
        copy: "複製",
        copy_success: "複製成功",
        delete: "刪除",
        freeze: "凍結",
        history: "記錄",
        input_full: "請填寫完整",
        last_price: "最新價",
        market: "趨勢",
        name: "名稱",
        no_data: "暫無數據",
        no_more: "沒有更多了",
        number: "數量",
        other: "其它",
        password_not_equal: "密碼不一致",
        product_list: "產品列表",
        quotation: "漲跌幅",
        set: "修改",
        setting: "設置",
        status: "狀態",
        submit: "提交",
        submit_order: "下單",
        tip: "提示",
        upload_pic: "上傳憑證",
        use: "可用"
    },
    trade: {
        balance: "餘額",
        best_price: "以當前最優價格交易",
        buy: "買入",
        buy_low: "做空",
        buy_price: "價格",
        buy_up: "做多",
        close_order: "平倉",
        closed: "休市",
        current_entrust: "當前委託",
        enter_price: "請輸入價格",
        high: "高",
        last_price: "市價",
        low: "低",
        no_mult: "暫無倍數",
        no_mult_config: "未配置槓桿,暫時無法下單",
        open: "开",
        operate: "操作",
        order: "訂單",
        order_confirm: "您的訂單已確認",
        order_page: "訂單頁面",
        price: "單價",
        sell: "賣出",
        time: "時間",
        trade_amount: "交易手數",
        trade_price: "交易額",
        type: "類型",
        volume: "總量"
    },
    user: {
        account_detail: "賬戶詳情",
        announ: "公告",
        announ_text: "由於政策原因，不向朝鮮，以色列，中國，瓦努阿圖，古巴提供服務。",
        area_code: "區號",
        can_use_asset: "可用資產",
        com_email: "投訴郵箱",
        confirm_logout: "是否退出登錄",
        confirm_reset: "是否重置",
        reset_success: "已重置",
        copy_invite_link: "複製邀請鏈接",
        dh: "電話",
        email: "郵箱",
        forgot_password: "忘記密碼",
        h_accept: "接受",
        h_second: "秒",
        h_timer: "倒計時",
        h_welc: "歡迎使用",
        input_email: "請輸入您的郵箱",
        input_invite_code: "請輸入邀請碼",
        input_old_password: "請輸入舊密碼",
        input_password: "請輸入密碼",
        input_phone: "請輸入您的手機號碼",
        invite_code: "邀請碼",
        join_us: "立即加入",
        login: "登錄",
        login_email: "郵箱登錄",
        login_phone: "手機登錄",
        logout: "退出登錄",
        logout_success: "退出成功",
        mock_account: "模擬帳戶",
        mock_login: "無需開戶，立即創建模擬賬戶進行交易！",
        new_paw: "New password",
        new_user: "新用戶？",
        only_true_account: "請註冊或者登入真實帳戶",
        password: "密碼",
        please_login: "請登錄",
        qh: "區號",
        qh_dh: "區號/電話",
        re_input_pass: "再次輸入密碼",
        register: "註冊",
        register_email: "郵箱註冊",
        register_phone: "手機註冊",
        reset_mock_account: "重置模擬賬戶",
        trade_account: "交易帳戶",
        useful_balance: "可用資金",
        user_center: "個人中心",
        welcome_login: "歡迎登錄",
        welcome_register: "歡迎註冊"
    },
    wallet: {
        bind: "綁定",
        bind_bank_card: "綁定銀行卡",
        bind_coin_address: "綁定數字貨幣地址",
        not_bind: "請先設置收款賬戶",
        save: "保存"
    },
    withdraw: {
        amount: "數量",
        calc_get: "預計到賬數量",
        no_pay_way: "該法幣賬戶暫無支付渠道",
        record: "提幣記錄",
        select_withdraw_address: "请选择提幣地址",
        send_amount: "到賬數量",
        symbol: "貨幣",
        unit: "單位",
        withdraw_address: "提幣地址",
        withdraw_coin: "提幣"
    }
}
obj.en = {
    auth: {
        back: "Reverse side",
        finish: "Certified",
        front: "Front",
        id_number: "Passport/ID number",
        input_id_num: "Please enter your passport/ID number",
        input_info: "Please complete your personal information",
        input_name: "Please enter your name",
        name: "Name",
        upload_back: "Upload the reverse side of your passport/certificate",
        upload_front: "Upload the front of your passport/certificate",
        upload_idcard: "Please upload the front and back of your passport/ID",
        waiting: "Audit",
        reject: "refuse",
        auth_again: "Re certification"
    },
    bank: {
        SWIFT: "SWIFT",
        account: "Payee Account",
        address: "Bank Address",
        bank_name: "Branch name",
        coin_type: "Beneficiary Currency",
        name: "Bank Name",
        remark: "Remark",
        swift_text: "SWIFT",
        username: "Payee"
    },
    financial: {
        bank: "Bank Card",
        bank_charge: "telegraph wire",
        charge_amount: "Number of coins",
        charge_coin: "Coins",
        charge_history: "Deposit record",
        charge_num: "Recharge amount",
        coin: "Digital Currency",
        collect_coin_type: "Receipt Currency",
        copy_address: "Copy Address",
        financial_records: "Financial Records",
        input_charge_amount: "Please enter the number of coins to be charged",
        input_charge_num: "Please enter the recharge amount",
        isfinish: "Completed",
        isreject: "Rejected",
        link_charge: "Recharge on the chain",
        select_network: "Select Network",
        uncheck: "Unchecked",
        unuseful: "Invalid",
        wallet_address: "Wallet Address"
    },
    forgot: {
        input_sms_code: "Please enter the verification code",
        reset: "Reset Password",
        send: "Send",
        sms_code: "Verification Code"
    },
    func: {
        billing_detail: "Billing Details",
        charge_coin: "Deposit",
        invite_friend: "Invite Friends",
        lang: "Language",
        real_auth: "Real Name Verification",
        reset_password: "Change Password",
        setting: "establishment",
        wallet: "Wallet",
        withdraw_coin: "Withdrawal"
    },
    order: {
        all_caution_money: "Current Margin",
        balance: "Balance",
        bond_fee: "Margin",
        buy_in: "Buy",
        calc_bond_fee: "Estimated Margin",
        calc_handle_fee: "Estimated Handling Fee",
        can_use: "Prepayment available",
        cancel: "Cancel order",
        close: "Close position",
        close_price: "Cheap warehouse price",
        confrim_close_order: "Whether to close the position",
        currency: "Transaction pairs",
        current_price: "Current price",
        dealing: "Pending Orders",
        detail: "Order Details",
        hand_num: "Lots",
        handle_fee: "Handling fee",
        hazard_rate: "Risk Rate",
        history: "History",
        holding: "Position holding",
        income: "profit",
        market_price: "Market Price",
        open_time: "Open time",
        order_id: "Reservation number",
        order_price: "Lower unit price",
        order_type: "direction",
        page: " Lots",
        pay_rate: "Prepayment Ratio",
        profit: "Profit and Loss",
        sell_out: "Sell",
        set_stop_price: "Save",
        stop_loss: "Set Loss",
        stop_profit: "Take Profit",
        times: "Multiplier",
        total: "Net value",
        total_ol: "total profit and loss",
        trade_amount: "Lots",
        trading: "Positions",
        un_deal_order: "Pending Orders",
        unit: "Each Lots"
    },
    public: {
        tocust: "Contact Now",
        add: "Add",
        cancel: "Cancel",
        chat: "Online Service",
        coming_soon: "Coming soon~",
        confirm: "confirmation",
        confirm_del: "Are you sure to delete?",
        copy: "Copy",
        copy_success: "Copied successfully",
        delete: "delete",
        freeze: "Freeze",
        history: "Record",
        input_full: "Please complete",
        last_price: "Last Price",
        market: "Trend",
        name: "Name",
        no_data: "No data yet",
        no_more: "No more",
        number: "Quantity",
        other: "Other",
        password_not_equal: "Password inconsistency",
        product_list: "Product List",
        quotation: "24H Change",
        set: "Set ",
        setting: "Setting",
        status: "Status",
        submit: "Submit",
        submit_order: "Submit Order",
        tip: "Prompt",
        upload_pic: "Uploading Certificate",
        use: "Available"
    },
    trade: {
        balance: "Balance",
        best_price: "Trading at the best current price",
        buy: "Buy",
        buy_low: "Shorting",
        buy_price: "Price",
        buy_up: "go long",
        close_order: "Close position",
        closed: "Closed",
        current_entrust: "Prior Commissioning",
        enter_price: "Please enter the price",
        high: "High",
        last_price: "Market Price",
        low: "Low",
        no_mult: "No multiple",
        no_mult_config: "No lever configured, unable to place an order temporarily",
        open: "Open",
        operate: "Operation",
        order: "Orders",
        order_confirm: "Your order has been confirmed",
        order_page: "Order Page",
        price: "price",
        sell: "Sell",
        time: "Date",
        trade_amount: "Trading lot size",
        trade_price: "Transaction amount",
        type: "Type",
        volume: "Total volume"
    },
    user: {
        account_detail: "Account Details",
        announ: "announcement",
        announ_text: "Due to policy reasons, services are not provided to North Korea, Israel, China, Vanuatu, and Cuba.",
        area_code: "Area Code",
        can_use_asset: "Available Assets",
        com_email: "Complaint email",
        confirm_logout: "Whether to withdraw from the register",
        confirm_reset: "Whether to return it",
        reset_success: "Reset successful",
        copy_invite_link: "Copy Invitation Link",
        dh: "telephone",
        email: "Email",
        forgot_password: "Forgot password",
        h_accept: "Accept",
        h_second: "second",
        h_timer: "countdown",
        h_welc: "Welcome to use",
        input_email: "Please enter your email address",
        input_invite_code: "Please enter the invitation code",
        input_old_password: "Please enter your old password",
        input_password: "Please enter your password",
        input_phone: "Please enter your cell phone number",
        invite_code: "Invitation Code",
        join_us: "Join Now",
        login: "Login",
        login_email: "Enter your Email",
        login_phone: "Mobile Login",
        logout: "Logout",
        logout_success: "Successful exit",
        mock_account: "Simulation Account",
        mock_login: "No need to open an account, create a demo account to trade now!",
        new_paw: "New password",
        new_user: "New User?",
        only_true_account: "Please register or log in to a real hard account",
        password: "Password",
        please_login: "Please log in",
        qh: "area code",
        qh_dh: "area code/telephone",
        re_input_pass: "Re-type the password",
        register: "Register",
        register_email: "Enter your Email",
        register_phone: "Mobile Registration",
        reset_mock_account: "Return simulated account",
        trade_account: "Trading account",
        useful_balance: "Available funds",
        user_center: "Mine",
        welcome_login: "Welcome to login",
        welcome_register: "Welcome to register"
    },
    wallet: {
        bind: "binding",
        bind_bank_card: "Bind bank card",
        bind_coin_address: "Bind digital currency address",
        not_bind: "Please set up a collection account first",
        save: "preservation"
    },
    withdraw: {
        amount: "Amount",
        calc_get: "It is expected that",
        no_pay_way: "There is no payment channel for the French currency account.",
        record: "Coin withdrawal record",
        select_withdraw_address: "Please select an address to call",
        send_amount: "Number of Receipts",
        symbol: "Currency",
        unit: "Unit",
        withdraw_address: "Address for coin withdrawals",
        withdraw_coin: "Submit withdrawal"
    }
}
obj.jp = {
    public: {
        tocust: "今すぐ連絡する",
        name: "の名前をあげる",
        last_price: "最終価格",
        market: "傾向",
        quotation: "24 H変更",
        no_data: "まだデータがありません",
        no_more: "もう二度と",
        confirm: "確認書",
        use: "入手可能",
        freeze: "とうけつ",
        number: "はかる",
        history: "レコード破り",
        other: "さらに",
        tip: "プロンプト",
        copy_success: "コピー成功",
        upload_pic: "証明書をアップロード中",
        submit: "送信",
        status: "ポジション",
        set: "設定＃セッテイ＃",
        chat: "オンラインサービス",
        copy: "レプリケーション",
        delete: "削除",
        confirm_del: "本当に削除しますか。",
        add: "追加",
        input_full: "記入してください",
        password_not_equal: "パスワードが一致しません",
        coming_soon: "近日発売～",
        cancel: "キャンセル",
        setting: "背景",
        product_list: "製品リスト",
        submit_order: "発注の発行"
    },
    user: {
        h_second: "秒",
        h_timer: "カウントダウン",
        h_accept: "受け入れる",
        h_welc: "ようこそ使用",
        announ_text: "政策上の理由により、北朝鮮、イスラエル、中国、バヌアツ、キューバにはサービスは提供されません。",
        announ: "発表",
        com_email: "苦情メール",
        dh: "電話",
        qh: "市外局番",
        qh_dh: "市外局番/電話番号",
        user_center: "こう",
        login_email: "電子メールを入力",
        login_phone: "携帯電話ログイン",
        input_email: "電子メールアドレスを入力してください",
        input_phone: "携帯番号を入力してください",
        password: "パスワード",
        new_paw: "新しいパスワード",
        input_password: "パスワードを入力してください",
        re_input_pass: "パスワードの再入力",
        input_old_password: "古いパスワードを入力してください",
        new_user: "新しいユーザー？",
        join_us: "今すぐ参加",
        forgot_password: "パスワードを忘れる",
        login: "ログイン",
        area_code: "地域コード",
        register: "登録",
        register_email: "電子メールを入力",
        register_phone: "携帯電話の登録",
        can_use_asset: "利用可能な資産",
        logout: "ログアウト",
        confirm_logout: "登録を終了するかどうか",
        logout_success: "終了に成功しました",
        mock_login: "アカウントを開設する必要はなく、シミュレーションアカウントを作成すれば取引ができます！",
        account_detail: "アカウントの詳細",
        mock_account: "アナログ勘定科目",
        invite_code: "招待コード",
        input_invite_code: "招待コードを入力してください",
        copy_invite_link: "招待状リンクのコピー",
        trade_account: "取引口座",
        only_true_account: "真のハードアカウントを登録またはログインしてください",
        reset_mock_account: "シミュレーション勘定科目を戻す",
        confirm_reset: "返品の有無",
        email: "郵便ポスト",
        welcome_login: "ログインへようこそ",
        welcome_register: "登録へようこそ",
        useful_balance: "使用可能な資金",
        please_login: "ログインしてください"
    },
    forgot: {
        sms_code: "認証コード",
        input_sms_code: "認証コードを入力してください",
        send: "郵便で送る",
        reset: "パスワードをリセット"
    },
    trade: {
        buy: "購入する",
        sell: "売る",
        last_price: "市場価格",
        best_price: "現在の最適価格で取引",
        buy_price: "価格",
        enter_price: "価格を入力してください",
        trade_amount: "トランザクションバッチサイズ",
        balance: "バランス",
        buy_up: "たくさん作る",
        buy_low: "たんらく",
        current_entrust: "事前デバッグ",
        type: "を選択してオプションを設定します。",
        time: "日付",
        price: "価格",
        trade_price: "取引金額",
        operate: "取引",
        close_order: "決済",
        high: "高い",
        open: "開く",
        low: "低い",
        volume: "そうたいせき",
        order: "オーダー",
        closed: "閉じる",
        order_confirm: "ご注文の確認",
        order_page: "「受注」ページ",
        no_mult: "倍数なし",
        no_mult_config: "レバーが設定されておらず、しばらく注文できません"
    },
    order: {
        current_price: "現行価格",
        total_ol: "損益総額",
        holding: "持倉",
        market_price: "市場価格",
        un_deal_order: "未決済注文",
        history: "履歴",
        hand_num: "ロット",
        stop_loss: "損切り",
        stop_profit: "利益確定",
        times: "じょうすう",
        handle_fee: "手数料",
        bond_fee: "証拠金",
        balance: "バランス",
        buy_in: "購入する",
        sell_out: "売る",
        trading: "位置",
        dealing: "未決済注文",
        profit: "損益",
        total: "ネット",
        pay_rate: "前払金比率",
        can_use: "繰上げ返済可能",
        trade_amount: "ロット ",
        unit: "ロット",
        page: "シーツ",
        hazard_rate: "リスク率",
        all_caution_money: "証拠金",
        detail: "オーダー詳細",
        close: "オフ位置",
        cancel: "注文の取消",
        calc_handle_fee: "予想手数料",
        calc_bond_fee: "予想証拠金",
        set_stop_price: "に救援物資を送る",
        currency: "取引ペア",
        order_id: "購読番号",
        order_type: "方向",
        order_price: "注文価格",
        close_price: "倉庫価格が安い",
        income: "損益",
        confrim_close_order: "ポジションを決済するかどうか",
        open_time: "新規取引の時間"
    },
    func: {
        charge_coin: "入金",
        withdraw_coin: "出金",
        billing_detail: "請求書詳細",
        real_auth: "実名認証",
        invite_friend: "友人を招く",
        reset_password: "パスワードの変更",
        setting: "きこう",
        lang: "言語",
        wallet: "財布"
    },
    financial: {
        financial_records: "財務記録",
        link_charge: "チェーンに充電する",
        bank_charge: "でんしんせん",
        charge_coin: "コイン枚",
        wallet_address: "ウォレットアドレス",
        copy_address: "アドレスのコピー",
        charge_amount: "コインの数",
        input_charge_amount: "チャージするコインの数を入力してください",
        collect_coin_type: "入金通貨",
        coin: "デジタル通貨",
        bank: "銀行カード",
        select_network: "ネットワークの選択",
        charge_num: "チャージ金額",
        input_charge_num: "チャージ金額を入力してください",
        charge_history: "預金記録",
        uncheck: "選択されていません",
        isfinish: "完了",
        isreject: "拒否されました",
        unuseful: "無効な"
    },
    bank: {
        SWIFT: "スイフト",
        account: "囗座番号",
        address: "預金種別",
        coin_type: "受益者通貨",
        name: "金融機関名",
        remark: "備考",
        swift_text: "支店番号",
        username: "口座名義",
        bank_name: "支店名"
    },
    auth: {
        upload_idcard: "パスポート/身分証明書の表と裏をアップロードしてください",
        front: "前面",
        upload_front: "パスポート/証明書の前面をアップロード",
        back: "裏面",
        upload_back: "パスポート/証明書の裏面をアップロード",
        input_info: "個人情報を入力してください",
        name: "の名前をあげる",
        input_name: "お名前を入力してください",
        id_number: "パスポート/身分証明書番号",
        input_id_num: "パスポート/身分証明書番号を入力してください",
        waiting: "監査",
        finish: "かくにん",
        reject: "拒否",
        auth_again: "再認定"
    },
    withdraw: {
        amount: "数量",
        symbol: "通貨",
        withdraw_coin: "引き出しの提出",
        no_pay_way: "外貨口座には支払ルートがありません。",
        calc_get: "見込み",
        record: "貨幣受取記録",
        send_amount: "領収書数量",
        withdraw_address: "貨幣受取先",
        unit: "ユニット",
        select_withdraw_address: "呼び出す住所を選択してください"
    },
    wallet: {
        bind_bank_card: "銀行カードのバインド",
        bind_coin_address: "バインドされたデジタル通貨アドレス",
        bind: "けつごう",
        save: "保護",
        not_bind: "先に入金口座を設定してください"
    }
}
obj.kr = {
    auth: {
        back: "뒷면",
        finish: "확인",
        front: "정면",
        id_number: "여권/주민등록번호",
        input_id_num: "여권/주민등록번호를 입력하십시오.",
        input_info: "개인 정보를 입력하십시오.",
        input_name: "이름을 입력하십시오.",
        name: "이름",
        upload_back: "여권/증서의 뒷면을 업로드합니다",
        upload_front: "여권 / 인증서 정면 업로드",
        upload_idcard: "여권/신분증의 앞면과 뒷면을 올려주십시오",
        waiting: "감사",
        reject: "거부",
        auth_again: "재인증"
    },
    bank: {
        SWIFT: "신속했어",
        account: "수취인 계좌",
        address: "은행 주소",
        bank_name: "지점명",
        coin_type: "수금 화폐",
        name: "은행명",
        remark: "코멘트",
        swift_text: "신속했어",
        username: "수취인"
    },
    financial: {
        bank: "은행카드",
        bank_charge: "전신선",
        charge_amount: "동전 수량",
        charge_coin: "동전",
        charge_history: "예금 기록",
        charge_num: "충전 금액",
        coin: "디지털 화폐",
        collect_coin_type: "수금 화폐",
        copy_address: "주소 복사",
        financial_records: "재무 기록",
        input_charge_amount: "충전할 동전의 수량을 입력하세요",
        input_charge_num: "충전 금액을 입력하세요",
        isfinish: "완료됨",
        isreject: "거부됨",
        link_charge: "체인에 충전",
        select_network: "네트워크 선택",
        uncheck: "선택되지 않음",
        unuseful: "잘못된",
        wallet_address: "지갑 주소"
    },
    forgot: {
        input_sms_code: "인증 코드를 입력하십시오.",
        reset: "비밀번호 재설정",
        send: "우송",
        sms_code: "인증 코드"
    },
    func: {
        billing_detail: "청구 내역",
        charge_coin: "보증금",
        invite_friend: "친구 초대",
        lang: "언어",
        real_auth: "실명제 검증",
        reset_password: "암호 수정",
        setting: "메커니즘",
        wallet: "지갑",
        withdraw_coin: "철회"
    },
    order: {
        all_caution_money: "현재 보증금",
        balance: "균형",
        bond_fee: "가장자리",
        buy_in: "구매",
        calc_bond_fee: "예상 이익",
        calc_handle_fee: "예상 수수료",
        can_use: "조기 상환 가능",
        cancel: "주문 취소",
        close: "위치 닫기",
        close_price: "창고 가격이 싸다",
        confrim_close_order: "이것은 싼 창고입니까?",
        currency: "트랜잭션 쌍",
        current_price: "현재 가격",
        dealing: "보류 중인 주문",
        detail: "주문 상세 정보",
        hand_num: "로츠",
        handle_fee: "수수료",
        hazard_rate: "위험률",
        history: "역사",
        holding: "창고 보유",
        income: "이익",
        market_price: "시장 가격",
        open_time: "개방 시간",
        order_id: "가입 번호",
        order_price: "단가가 낮다",
        order_type: "방향",
        page: "시트",
        pay_rate: "선불 비율",
        profit: "손익",
        sell_out: "팔다",
        set_stop_price: "구원",
        stop_loss: "손실 설정",
        stop_profit: "이익 설정",
        times: "승수",
        total: "순가치",
        total_ol: "손익 총액",
        trade_amount: "로츠",
        trading: "위치",
        un_deal_order: "보류 중인 주문",
        unit: "종이마다"
    },
    public: {
        tocust: "지금 연락",
        add: "추가",
        cancel: "취소",
        chat: "온라인 서비스",
        coming_soon: "출시 예정 ~",
        confirm: "확인서",
        confirm_del: "삭제하시겠습니까?",
        copy: "복제",
        copy_success: "복제 성공",
        delete: "삭제",
        freeze: "동결",
        history: "기록",
        input_full: "기입해 주십시오",
        last_price: "마지막 가격",
        market: "추세",
        name: "이름",
        no_data: "데이터가 없습니다.",
        no_more: "더 이상",
        number: "재다",
        other: "또한",
        password_not_equal: "암호 불일치",
        product_list: "제품 목록",
        quotation: "24H 변경 내용",
        set: "설치",
        setting: "배경",
        status: "지위",
        submit: "제출",
        submit_order: "주문 전송",
        tip: "재촉",
        upload_pic: "인증서 업로드 중",
        use: "획득 가능"
    },
    trade: {
        balance: "균형",
        best_price: "현재 최고의 가격으로 거래",
        buy: "구매",
        buy_low: "합선",
        buy_price: "가격",
        buy_up: "많이 하다",
        close_order: "위치 닫기",
        closed: "닫기",
        current_entrust: "사전 디버깅",
        enter_price: "가격을 입력하십시오.",
        high: "높음",
        last_price: "시장 가격",
        low: "낮음",
        no_mult: "배수가 없다",
        no_mult_config: "레버가 구성되지 않아 일시적으로 주문할 수 없음",
        open: "열기",
        operate: "활동",
        order: "주문",
        order_confirm: "주문 확인",
        order_page: "주문 페이지",
        price: "가격",
        sell: "팔다",
        time: "날짜",
        trade_amount: "거래 대량 크기",
        trade_price: "거래 금액",
        type: "유형",
        volume: "전체 볼륨"
    },
    user: {
        account_detail: "계정 세부 정보",
        announ: "공지",
        announ_text: "정책상의 이유로 북한, 이스라엘, 중국, 바누아투, 쿠바에는 서비스가 제공되지 않습니다.",
        area_code: "지역 코드",
        can_use_asset: "가용 자산",
        com_email: "불만 이메일",
        confirm_logout: "등록 종료 여부",
        confirm_reset: "반품 여부",
        copy_invite_link: "초대 링크 복사",
        dh: "전화",
        email: "우편함",
        forgot_password: "암호를 잊었습니다.",
        h_accept: "수락",
        h_second: "초",
        h_timer: "카운트다운",
        h_welc: "사용을 환영합니다",
        input_email: "이메일 주소를 입력하십시오.",
        input_invite_code: "초대 코드를 입력하십시오.",
        input_old_password: "이전 암호를 입력하십시오.",
        input_password: "암호를 입력하십시오.",
        input_phone: "휴대폰 번호를 입력하십시오.",
        invite_code: "초대 코드",
        join_us: "지금 가입",
        login: "로그인",
        login_email: "이메일 입력",
        login_phone: "휴대폰 로그인",
        logout: "로그아웃",
        logout_success: "종료 성공",
        mock_account: "아날로그 계정",
        mock_login: "계좌를 개설할 필요 없이 모의 계좌를 만들면 거래를 진행할 수 있습니다!",
        new_paw: "새 암호",
        new_user: "새 사용자?",
        only_true_account: "실제 하드 계정을 등록하거나 로그인하십시오.",
        password: "은어",
        please_login: "로그인",
        qh: "지역번호",
        qh_dh: "지역번호/전화",
        re_input_pass: "암호 다시 입력",
        register: "등록",
        register_email: "이메일 입력",
        register_phone: "휴대폰 등록",
        reset_mock_account: "아날로그 계정으로 돌아가기",
        trade_account: "거래 계좌",
        useful_balance: "가용 자금",
        user_center: "광산",
        welcome_login: "로그인 시작",
        welcome_register: "등록 시작"
    },
    wallet: {
        bind: "결합",
        bind_bank_card: "바인딩 카드",
        bind_coin_address: "바인딩 디지털 화폐 주소",
        not_bind: "먼저 수금 계좌를 설정하세요.",
        save: "보호"
    },
    withdraw: {
        amount: "수량",
        calc_get: "예상",
        no_pay_way: "법화 계좌에는 지불 경로가 없다.",
        record: "코인 레코드",
        select_withdraw_address: "호출할 주소를 선택하십시오.",
        send_amount: "영수증 수량",
        symbol: "통화",
        unit: "유닛",
        withdraw_address: "코인 주소",
        withdraw_coin: "인출금을 제출하다"
    }
}
obj.th = {
    auth: {
        back: "กลับด้าน",
        finish: "ยืนยัน",
        front: "ด้านหน้า",
        id_number: "หมายเลขหนังสือเดินทาง / บัตรประชาชน",
        input_id_num: "กรุณากรอกหมายเลขหนังสือเดินทาง/บัตรประชาชน",
        input_info: "กรุณากรอกข้อมูลส่วนตัว",
        input_name: "กรุณาใส่ชื่อของคุณ",
        name: "ชื่อ",
        upload_back: "อัปโหลดตรงข้ามหนังสือเดินทาง / ใบรับรองของคุณ",
        upload_front: "อัปโหลดหนังสือเดินทาง / ใบรับรองของคุณ",
        upload_idcard: "กรุณาอัพโหลดหนังสือเดินทาง / บัตรประชาชนด้านหน้าและด้านหลัง",
        waiting: "การตรวจสอบบัญชี",
        reject: "ปฏิเสธ",
        auth_again: "รับรองอีกครั้ง"
    },
    bank: {
        SWIFT: "รวดเร็ว",
        account: "บัญชีผู้รับเงิน",
        address: "ที่อยู่ธนาคาร",
        bank_name: "ชื่อสาขา",
        coin_type: "สกุลเงินที่รับ",
        name: "ชื่อธนาคาร",
        remark: "รีวิว",
        swift_text: "รวดเร็ว",
        username: "ผู้รับเงิน"
    },
    financial: {
        bank: "บัตรธนาคาร",
        bank_charge: "สายโทรเลข",
        charge_amount: "จำนวนเหรียญ",
        charge_coin: "เหรียญ",
        charge_history: "บันทึกการฝากเงิน",
        charge_num: "จำนวนเงินที่เติมเงิน",
        coin: "สกุลเงินดิจิตอล",
        collect_coin_type: "สกุลเงินที่รับ",
        copy_address: "คัดลอกที่อยู่",
        financial_records: "บันทึกทางการเงิน",
        input_charge_amount: "กรุณาใส่จำนวนเหรียญที่ต้องการเติมเงิน",
        input_charge_num: "กรุณาใส่จำนวนเงินที่เติมเงิน",
        isfinish: "เสร็จสมบูรณ์",
        isreject: "ถูกปฏิเสธ",
        link_charge: "การชาร์จไฟบนโซ่",
        select_network: "เลือกเครือข่าย",
        uncheck: "ไม่ถูกเลือก",
        unuseful: "ไม่ถูกต้อง",
        wallet_address: "ที่อยู่กระเป๋าสตางค์"
    },
    forgot: {
        input_sms_code: "กรุณากรอกรหัสยืนยัน",
        reset: "รีเซ็ตรหัสผ่าน",
        send: "ไปรษณีย์",
        sms_code: "รหัสยืนยัน"
    },
    func: {
        billing_detail: "รายละเอียดบิล",
        charge_coin: "เงินฝาก",
        invite_friend: "เชิญเพื่อน",
        lang: "ภาษา",
        real_auth: "การตรวจสอบระบบชื่อจริง",
        reset_password: "แก้ไขรหัสผ่าน",
        setting: "สถาบัน",
        wallet: "กระเป๋าสตางค์",
        withdraw_coin: "การถอนเงิน"
    },
    order: {
        all_caution_money: "มาร์จิ้นปัจจุบัน",
        balance: "สมดุล",
        bond_fee: "ขอบ",
        buy_in: "ซื้อ",
        calc_bond_fee: "กำไรที่คาดการณ์ไว้",
        calc_handle_fee: "ค่าธรรมเนียมการดำเนินการโดยประมาณ",
        can_use: "สามารถชำระหนี้ล่วงหน้าได้",
        cancel: "การยกเลิกคำสั่งซื้อ",
        close: "ปิดสถานที่",
        close_price: "คลังสินค้าราคาถูก",
        confrim_close_order: "คลังสินค้าราคาถูก",
        currency: "การประมวลผลธุรกรรมคู่",
        current_price: "ราคาปัจจุบัน",
        dealing: "คำสั่งซื้อที่รอดำเนินการ",
        detail: "รายละเอียดการสั่งซื้อ",
        hand_num: "โลทส์",
        handle_fee: "ค่าธรรมเนียมการจัดการ",
        hazard_rate: "อัตราความเสี่ยง",
        history: "ประวัติความเป็นมา",
        holding: "เปิดสถานะ",
        income: "กำไร",
        market_price: "ราคาตลาด",
        open_time: "เวลาเปิดทำการ",
        order_id: "หมายเลขการจอง",
        order_price: "ราคาต่อหน่วยต่ำกว่า",
        order_type: "ทิศทาง",
        page: "ผ้าปูที่นอน",
        pay_rate: "อัตราการชำระเงินล่วงหน้า",
        profit: "กำไรและขาดทุน",
        sell_out: "ขาย",
        set_stop_price: "ความรอด",
        stop_loss: "ตั้งค่าการสูญเสีย",
        stop_profit: "ตั้งค่ากำไร",
        times: "ตัวคูณ",
        total: "มูลค่าสุทธิ",
        total_ol: "กำไรและขาดทุนทั้งหมด",
        trade_amount: "โลทส์",
        trading: "สถานที่ตั้ง",
        un_deal_order: "คำสั่งซื้อที่รอดำเนินการ",
        unit: "กระดาษแต่ละแผ่น"
    },
    public: {
        tocust: "ติดต่อเรา",
        add: "เพิ่ม",
        cancel: "การยกเลิก",
        chat: "บริการออนไลน์",
        coming_soon: "เร็ว ๆ นี้ ~",
        confirm: "การยืนยัน",
        confirm_del: "คุณแน่ใจนะว่าต้องการลบ?",
        copy: "คัดลอก",
        copy_success: "คัดลอกสำเร็จ",
        delete: "ลบ",
        freeze: "แช่แข็ง",
        history: "บันทึก",
        input_full: "กรุณากรอก",
        last_price: "ราคาล่าสุด",
        market: "แนวโน้ม",
        name: "ชื่อ",
        no_data: "ยังไม่มีข้อมูล",
        no_more: "ไม่มีอีกต่อไป",
        number: "ปริมาณ",
        other: "เพิ่มเติม",
        password_not_equal: "รหัสผ่านไม่สอดคล้องกัน",
        product_list: "รายการสินค้า",
        quotation: "24H เปลี่ยน",
        set: "การตั้งค่า",
        setting: "พื้นหลัง",
        status: "สถานะ",
        submit: "ส่ง",
        submit_order: "ส่งคำสั่งซื้อ",
        tip: "กระตุ้น",
        upload_pic: "กำลังอัปโหลดใบรับรอง",
        use: "ใช้ได้"
    },
    trade: {
        balance: "สมดุล",
        best_price: "เทรดด้วยราคาที่ดีที่สุดในปัจจุบัน",
        buy: "ซื้อ",
        buy_low: "ลัดวงจร",
        buy_price: "ราคา",
        buy_up: "ทำ Long",
        close_order: "ปิดสถานที่",
        closed: "ปิด",
        current_entrust: "การว่าจ้างล่วงหน้า",
        enter_price: "กรุณากรอกราคา",
        high: "สูง",
        last_price: "ราคาตลาด",
        low: "ต่ำ",
        no_mult: "ไม่มีทวีคูณ",
        no_mult_config: "ไม่มีการกำหนดค่าเลเวอเรจและไม่สามารถวางคำสั่งได้ชั่วคราว",
        open: "เปิด",
        operate: "กิจกรรม",
        order: "การสั่งซื้อ",
        order_confirm: "คำสั่งซื้อของคุณได้รับการยืนยันแล้ว",
        order_page: "หน้าสั่งซื้อ",
        price: "ราคา",
        sell: "ขาย",
        time: "วันที่",
        trade_amount: "ขนาดล็อตการเทรด",
        trade_price: "จำนวนธุรกรรม",
        type: "ประเภท",
        volume: "ปริมาณรวม"
    },
    user: {
        account_detail: "รายละเอียดบัญชี",
        announ: "ประกาศ",
        announ_text: "เนื่องจากเหตุผลด้านนโยบาย จึงไม่มีการให้บริการแก่เกาหลีเหนือ อิสราเอล จีน วานูอาตู และคิวบา",
        area_code: "รหัสอำเภอ",
        can_use_asset: "สินทรัพย์ที่มีอยู่",
        com_email: "กล่องรับเรื่องร้องทุกข์",
        confirm_logout: "ไม่ว่าจะเป็นการออกจากการลงทะเบียน",
        confirm_reset: "ไม่ว่าจะเป็นการคืนสินค้า",
        copy_invite_link: "คัดลอกลิงก์เชิญ",
        dh: "โทรศัพท์",
        email: "ตู้ไปรษณีย์",
        forgot_password: "ลืมรหัสผ่าน",
        h_accept: "ยอมรับ",
        h_second: "วินาที",
        h_timer: "นับถอยหลัง",
        h_welc: "ยินดีต้อนรับสู่การใช้งาน",
        input_email: "โปรดป้อนที่อยู่อีเมลของคุณ",
        input_invite_code: "กรุณากรอกรหัสเชิญ",
        input_old_password: "กรุณาใส่รหัสผ่านเก่าของคุณ",
        input_password: "กรุณากรอกรหัสผ่าน",
        input_phone: "กรุณากรอกหมายเลขโทรศัพท์มือถือ",
        invite_code: "รหัสเชิญ",
        join_us: "เข้าร่วมตอนนี้",
        login: "เข้าสู่ระบบ",
        login_email: "ป้อนอีเมลของคุณ",
        login_phone: "เข้าสู่ระบบโทรศัพท์มือถือ",
        logout: "ออกจากระบบ",
        logout_success: "ถอนตัวได้สำเร็จ",
        mock_account: "บัญชีทดลอง",
        mock_login: "ไม่จำเป็นต้องเปิดบัญชี สร้างบัญชีทดลองเพื่อการเทรด!",
        new_paw: "รหัสผ่านใหม่",
        new_user: "ผู้ใช้ใหม่?",
        only_true_account: "กรุณาลงทะเบียนหรือเข้าสู่ระบบบัญชี True Hard",
        password: "รหัสลับ",
        please_login: "กรุณาเข้าสู่ระบบ",
        qh: "รหัสพื้นที่",
        qh_dh: "รหัสพื้นที่/โทรศัพท์",
        re_input_pass: "พิมพ์รหัสผ่านใหม่",
        register: "ลงทะเบียน",
        register_email: "ป้อนอีเมลของคุณ",
        register_phone: "ลงทะเบียนโทรศัพท์มือถือ",
        reset_mock_account: "กลับไปที่บัญชีทดลอง",
        trade_account: "บัญชีเทรด",
        useful_balance: "เงินทุนที่มีอยู่",
        user_center: "เหมือง",
        welcome_login: "ยินดีต้อนรับเข้าสู่ระบบ",
        welcome_register: "ยินดีต้อนรับสู่การลงทะเบียน"
    },
    wallet: {
        bind: "รวม",
        bind_bank_card: "ผูกบัตรธนาคาร",
        bind_coin_address: "ผูกที่อยู่สกุลเงินดิจิตอล",
        not_bind: "โปรดตั้งบัญชีรับเงินก่อน",
        save: "การป้องกัน"
    },
    withdraw: {
        amount: "ปริมาณ",
        calc_get: "คาดว่า",
        no_pay_way: "บัญชีสกุลเงินฝรั่งเศสไม่มีช่องทางการชำระเงิน",
        record: "บันทึกการรับเงิน",
        select_withdraw_address: "โปรดเลือกที่อยู่ที่คุณต้องการโทร",
        send_amount: "จำนวนใบเสร็จรับเงิน",
        symbol: "สกุลเงิน",
        unit: "หน่วย",
        withdraw_address: "ที่อยู่รับเงิน",
        withdraw_coin: "ส่งถอนเงิน"
    }
}
obj.vn = {
    auth: {
        back: "Trái ngược",
        finish: "Xác nhận",
        front: "Mặt trước",
        id_number: "Số hộ chiếu/ID",
        input_id_num: "Vui lòng nhập số hộ chiếu/ID của bạn",
        input_info: "Vui lòng điền thông tin cá nhân của bạn",
        input_name: "Vui lòng nhập tên của bạn",
        name: "Tên",
        upload_back: "Tải lên mặt trái của hộ chiếu/giấy chứng nhận của bạn",
        upload_front: "Tải lên mặt trước của hộ chiếu/chứng chỉ của bạn",
        upload_idcard: "Vui lòng tải lên mặt trước và mặt sau của hộ chiếu/ID của bạn",
        waiting: "Kiểm toán",
        reject: "Từ chối",
        auth_again: "Chứng nhận lại"
    },
    bank: {
        SWIFT: "nhanh chóng",
        account: "Tài khoản người nhận",
        address: "Địa chỉ ngân hàng",
        bank_name: "Tên chi nhánh",
        coin_type: "Thu tiền",
        name: "Tên ngân hàng",
        remark: "Bình luận",
        swift_text: "nhanh chóng",
        username: "Người nhận tiền"
    },
    financial: {
        bank: "Thẻ ngân hàng",
        bank_charge: "Điện tín",
        charge_amount: "Số lượng coin",
        charge_coin: "Tiền xu",
        charge_history: "Hồ sơ tiền gửi",
        charge_num: "Số tiền nạp",
        coin: "Tiền điện tử",
        collect_coin_type: "Thu tiền",
        copy_address: "Sao chép địa chỉ",
        financial_records: "Hồ sơ tài chính",
        input_charge_amount: "Vui lòng nhập số tiền bạn muốn nạp",
        input_charge_num: "Vui lòng nhập số tiền nạp",
        isfinish: "Đã hoàn thành",
        isreject: "Bị từ chối",
        link_charge: "Sạc trên chuỗi",
        select_network: "Chọn Network",
        uncheck: "Chưa chọn",
        unuseful: "Không hợp lệ",
        wallet_address: "Địa chỉ ví"
    },
    forgot: {
        input_sms_code: "Vui lòng nhập CAPTCHA",
        reset: "Đặt lại mật khẩu",
        send: "Thư điện tử",
        sms_code: "Mã xác nhận"
    },
    func: {
        billing_detail: "Chi tiết hóa đơn",
        charge_coin: "Đặt cọc",
        invite_friend: "Mời bạn bè",
        lang: "Ngôn ngữ",
        real_auth: "Xác minh tên thật",
        reset_password: "Thay đổi mật khẩu",
        setting: "Cơ quan",
        wallet: "Ví tiền",
        withdraw_coin: "Rút tiền"
    },
    order: {
        all_caution_money: "Ký quỹ hiện tại",
        balance: "Cân bằng",
        bond_fee: "Cạnh",
        buy_in: "Mua hàng",
        calc_bond_fee: "Lợi nhuận dự kiến",
        calc_handle_fee: "Phí xử lý dự kiến",
        can_use: "Có thể trả trước",
        cancel: "Hủy đặt hàng",
        close: "Đóng vị trí",
        close_price: "Kho giá rẻ",
        confrim_close_order: "Đây có phải là một nhà kho giá rẻ?",
        currency: "Xử lý giao dịch đúng",
        current_price: "Giá hiện tại",
        dealing: "Lệnh chờ xử lý",
        detail: "Chi tiết đặt hàng",
        hand_num: "Trang chủ",
        handle_fee: "Phí xử lý",
        hazard_rate: "Tỷ lệ rủi ro",
        history: "Lịch sử",
        holding: "Giữ vị trí",
        income: "Lợi nhuận",
        market_price: "Giá thị trường",
        open_time: "Giờ mở cửa",
        order_id: "Số đặt phòng",
        order_price: "Đơn giá thấp hơn",
        order_type: "Hướng dẫn",
        page: "Khăn trải giường",
        pay_rate: "Tỷ lệ trả trước",
        profit: "Lợi nhuận",
        sell_out: "Bán hàng",
        set_stop_price: "Giải cứu",
        stop_loss: "Đặt tổn thất",
        stop_profit: "Thiết lập lợi nhuận",
        times: "Nhân",
        total: "Giá trị ròng",
        total_ol: "Tổng lợi nhuận và thua lỗ",
        trade_amount: "Trang chủ",
        trading: "Địa điểm",
        un_deal_order: "Lệnh chờ xử lý",
        unit: "Mỗi tờ giấy"
    },
    public: {
        tocust: "Liên hệ",
        add: "Thêm",
        cancel: "Hủy bỏ",
        chat: "Dịch vụ trực tuyến",
        coming_soon: "Sắp ra mắt~",
        confirm: "Xác nhận",
        confirm_del: "Bạn có chắc chắn muốn xóa nó?",
        copy: "Sao chép",
        copy_success: "Sao chép thành công",
        delete: "Xóa",
        freeze: "Đóng băng",
        history: "Hồ sơ",
        input_full: "Vui lòng điền",
        last_price: "Giá cuối cùng",
        market: "Xu hướng",
        name: "Tên",
        no_data: "Chưa có dữ liệu",
        no_more: "Không còn nữa.",
        number: "Số lượng",
        other: "Thêm",
        password_not_equal: "Mật khẩu không phù hợp",
        product_list: "Danh sách sản phẩm",
        quotation: "24H thay đổi",
        set: "Thiết lập",
        setting: "Nền",
        status: "Địa vị",
        submit: "Giới thiệu",
        submit_order: "Gửi đơn đặt hàng",
        tip: "Khuyến mãi",
        upload_pic: "Đang tải giấy chứng nhận lên",
        use: "Có sẵn"
    },
    trade: {
        balance: "Cân bằng",
        best_price: "Giao dịch với giá tốt nhất hiện tại",
        buy: "Mua hàng",
        buy_low: "Ngắn mạch",
        buy_price: "Giá cả",
        buy_up: "Làm nhiều",
        close_order: "Đóng vị trí",
        closed: "Đóng cửa",
        current_entrust: "Sửa lỗi trước",
        enter_price: "Vui lòng nhập giá",
        high: "Cao",
        last_price: "Giá thị trường",
        low: "Thấp",
        no_mult: "Không có Multiple",
        no_mult_config: "Đòn bẩy chưa được cấu hình, không thể đặt lệnh tạm thời",
        open: "Mở",
        operate: "Hoạt động",
        order: "Đặt hàng",
        order_confirm: "Đơn hàng của bạn đã được xác nhận",
        order_page: "Trang đặt hàng",
        price: "Giá cả",
        sell: "Bán hàng",
        time: "Ngày",
        trade_amount: "Kích thước lô giao dịch",
        trade_price: "Số tiền giao dịch",
        type: "Loại",
        volume: "Tổng khối lượng"
    },
    user: {
        account_detail: "Chi tiết tài khoản",
        announ: "thông báo",
        announ_text: "Vì lý do chính sách, dịch vụ không được cung cấp cho Bắc Triều Tiên, Israel, Trung Quốc, Vanuatu và Cuba.",
        area_code: "Mã vùng",
        can_use_asset: "Tài sản có sẵn",
        com_email: "Email khiếu nại",
        confirm_logout: "Đăng ký hay không",
        confirm_reset: "Trả lại hàng",
        copy_invite_link: "Sao chép liên kết mời",
        dh: "điện thoại",
        email: "Hộp thư",
        forgot_password: "Quên mật khẩu",
        h_accept: "Chấp nhận",
        h_second: "giây",
        h_timer: "đếm ngược",
        h_welc: "Chào mừng bạn sử dụng",
        input_email: "Vui lòng nhập địa chỉ email của bạn",
        input_invite_code: "Vui lòng nhập mã mời",
        input_old_password: "Vui lòng nhập mật khẩu cũ của bạn",
        input_password: "Vui lòng nhập mật khẩu",
        input_phone: "Vui lòng nhập số điện thoại di động của bạn",
        invite_code: "Mã mời",
        join_us: "Tham gia ngay",
        login: "Đăng nhập",
        login_email: "Nhập email của bạn",
        login_phone: "Đăng nhập di động",
        logout: "Đăng xuất",
        logout_success: "Thoát thành công",
        mock_account: "Tài khoản Demo",
        mock_login: "Không cần mở tài khoản, hãy tạo tài khoản demo để giao dịch!",
        new_paw: "Mật khẩu mới",
        new_user: "Người dùng mới?",
        only_true_account: "Vui lòng đăng ký hoặc đăng nhập vào tài khoản Real Hard",
        password: "Mật ngữ",
        please_login: "Đăng nhập",
        qh: "mã vùng",
        qh_dh: "mã vùng/điện thoại",
        re_input_pass: "Nhập lại mật khẩu",
        register: "Đăng ký",
        register_email: "Nhập email của bạn",
        register_phone: "Đăng ký di động",
        reset_mock_account: "Quay lại tài khoản demo",
        trade_account: "Tài khoản giao dịch",
        useful_balance: "Tiền có sẵn",
        user_center: "mỏ",
        welcome_login: "Chào mừng đăng nhập",
        welcome_register: "Chào mừng đăng ký"
    },
    wallet: {
        bind: "Liên kết",
        bind_bank_card: "Thẻ ngân hàng ràng buộc",
        bind_coin_address: "Địa chỉ Digital Currency",
        not_bind: "Vui lòng thiết lập tài khoản nhận tiền trước",
        save: "Bảo vệ"
    },
    withdraw: {
        amount: "Số lượng",
        calc_get: "Dự kiến",
        no_pay_way: "Tài khoản fiat không có kênh thanh toán.",
        record: "Hồ sơ rút tiền",
        select_withdraw_address: "Vui lòng chọn địa chỉ bạn muốn gọi",
        send_amount: "Số lượng Receipt",
        symbol: "Tiền tệ",
        unit: "Đơn vị",
        withdraw_address: "Địa chỉ rút tiền",
        withdraw_coin: "Gửi rút tiền"
    }
}
obj.fr = {
    auth: {
        back: "Le contraire",
        finish: "Confirmé",
        front: "Le Front",
        id_number: "Numéro de passeport / carte d'identité",
        input_id_num: "Veuillez entrer votre numéro de passeport / carte d'identité",
        input_info: "Veuillez remplir vos informations personnelles",
        input_name: "Veuillez entrer votre nom",
        name: "Le nom",
        upload_back: "Télécharger le verso de votre passeport / certificat",
        upload_front: "Téléchargez votre passeport / certificat recto",
        upload_idcard: "Veuillez télécharger le recto et le verso de votre passeport / carte d'identité",
        waiting: "Audit",
        reject: "Refusé",
        auth_again: "Certifié à nouveau"
    },
    bank: {
        SWIFT: "Rapide",
        account: "Compte du bénéficiaire",
        address: "Adresse de la Banque",
        bank_name: "Nom de la filiale",
        coin_type: "Monnaie de collecte",
        name: "Nom de la Banque",
        remark: "Commentaires",
        swift_text: "Rapide",
        username: "Le bénéficiaire"
    },
    financial: {
        bank: "Carte bancaire",
        bank_charge: "Ligne télégraphique",
        charge_amount: "Nombre de pièces de monnaie",
        charge_coin: "Pièces de monnaie",
        charge_history: "Enregistrement des dépôts",
        charge_num: "Montant de la recharge",
        coin: "Monnaie numérique",
        collect_coin_type: "Monnaie de collecte",
        copy_address: "Copier l'adresse",
        financial_records: "Dossiers financiers",
        input_charge_amount: "Veuillez entrer le nombre de pièces à recharger",
        input_charge_num: "Veuillez entrer le montant de la recharge",
        isfinish: "Terminé",
        isreject: "Refusé",
        link_charge: "Charge sur la chaîne",
        select_network: "Choisir un réseau",
        uncheck: "Non sélectionné",
        unuseful: "Invalide",
        wallet_address: "Adresse du portefeuille"
    },
    forgot: {
        input_sms_code: "Veuillez entrer le Code de vérification",
        reset: "Réinitialiser le mot de passe",
        send: "Par courrier",
        sms_code: "Code de vérification"
    },
    func: {
        billing_detail: "Détails de facturation",
        charge_coin: "Dépôt",
        invite_friend: "Inviter un ami",
        lang: "Langue",
        real_auth: "Vérification du vrai nom",
        reset_password: "Modifier le mot de passe",
        setting: "Institutions",
        wallet: "Portefeuille",
        withdraw_coin: "Retrait"
    },
    order: {
        all_caution_money: "Marge actuelle",
        balance: "Équilibre",
        bond_fee: "Le bord",
        buy_in: "Acheter",
        calc_bond_fee: "Bénéfices attendus",
        calc_handle_fee: "Frais de traitement estimés",
        can_use: "Remboursement anticipé possible",
        cancel: "Annuler une commande",
        close: "Position fermée",
        close_price: "Entrepôt prix pas cher",
        confrim_close_order: "Est - ce un entrepôt bon marché?",
        currency: "Traitement des transactions",
        current_price: "Prix actuel",
        dealing: "Commandes en attente de traitement",
        detail: "Détails de la commande",
        hand_num: "Lotz",
        handle_fee: "Frais de traitement",
        hazard_rate: "Taux de risque",
        history: "Historique",
        holding: "Ouverture des positions",
        income: "Bénéfices",
        market_price: "Prix du marché",
        open_time: "Heures d'ouverture",
        order_id: "Numéro de réservation",
        order_price: "Prix unitaire inférieur",
        order_type: "Direction",
        page: "Linge de lit",
        pay_rate: "Taux de paiement anticipé",
        profit: "Gains et pertes",
        sell_out: "Vendre",
        set_stop_price: "Sauver",
        stop_loss: "Définir les pertes",
        stop_profit: "Fixer les bénéfices",
        times: "Multiplicateur",
        total: "Valeur nette",
        total_ol: "Total des profits et pertes",
        trade_amount: "Lotz",
        trading: "Localisation",
        un_deal_order: "Commandes en attente de traitement",
        unit: "Chaque feuille de papier"
    },
    public: {
        tocust: "Contactez maintenant",
        add: "Ajouter",
        cancel: "Annulation",
        chat: "Services en ligne",
        coming_soon: "Bientôt disponible ~",
        confirm: "Confirmation",
        confirm_del: "Êtes - vous sûr de vouloir supprimer?",
        copy: "Copier",
        copy_success: "Copie réussie",
        delete: "Supprimer",
        freeze: "Congelé",
        history: "Enregistrement",
        input_full: "Veuillez remplir",
        last_price: "Dernier prix",
        market: "Les tendances",
        name: "Le nom",
        no_data: "Pas encore de données",
        no_more: "N'est plus",
        number: "Quantité",
        other: "En outre",
        password_not_equal: "Incohérence du mot de passe",
        product_list: "Liste des produits",
        quotation: "24h changement",
        set: "Paramètres",
        setting: "Contexte",
        status: "Statut",
        submit: "Soumettre",
        submit_order: "Soumettre une commande",
        tip: "Provoquer",
        upload_pic: "Téléchargement du certificat",
        use: "Disponible"
    },
    trade: {
        balance: "Équilibre",
        best_price: "Tradez au meilleur prix actuel",
        buy: "Acheter",
        buy_low: "Court - circuit",
        buy_price: "Prix",
        buy_up: "Faire long",
        close_order: "Position fermée",
        closed: "Fermé",
        current_entrust: "Pré - débogage",
        enter_price: "Veuillez entrer le prix",
        high: "Haute",
        last_price: "Prix du marché",
        low: "Faible",
        no_mult: "Pas de multiples",
        no_mult_config: "Levier non configuré, impossible temporairement de passer une commande",
        open: "Ouvrir",
        operate: "Activités",
        order: "Commandes",
        order_confirm: "Votre commande est confirmée",
        order_page: "Page de commande",
        price: "Prix",
        sell: "Vendre",
        time: "Date",
        trade_amount: "Taille du lot de transactions",
        trade_price: "Montant de la transaction",
        type: "Type",
        volume: "Volume total"
    },
    user: {
        account_detail: "Détails du compte",
        announ: "annonce",
        announ_text: "Pour des raisons politiques, les services ne sont pas fournis à la Corée du Nord, à Israël, à la Chine, au Vanuatu et à Cuba.",
        area_code: "Code de la région",
        can_use_asset: "Actifs disponibles",
        com_email: "Boîte aux lettres de réclamation",
        confirm_logout: "Se retirer de l'enregistrement ou non",
        confirm_reset: "Retours ou non",
        copy_invite_link: "Copier le lien d'invitation",
        dh: "téléphone",
        email: "Boîte postale",
        forgot_password: "Mot de passe oublié",
        h_accept: "Accepter",
        h_second: "seconde",
        h_timer: "compte à rebours",
        h_welc: "Bienvenue dans l'utilisation",
        input_email: "Veuillez entrer votre adresse e - mail",
        input_invite_code: "Veuillez entrer le Code d'invitation",
        input_old_password: "Veuillez entrer votre ancien mot de passe",
        input_password: "Veuillez entrer votre mot de passe",
        input_phone: "Veuillez entrer votre numéro de téléphone portable",
        invite_code: "Code d'invitation",
        join_us: "Inscrivez - vous maintenant",
        login: "Se connecter",
        login_email: "Entrez votre email",
        login_phone: "Connexion mobile",
        logout: "Déconnexion",
        logout_success: "Sortie réussie",
        mock_account: "Compte de simulation",
        mock_login: "Pas besoin d'ouvrir un compte, créez un compte démo pour Trader!",
        new_paw: "Nouveau mot de passe",
        new_user: "Un nouvel utilisateur?",
        only_true_account: "Veuillez vous inscrire ou vous connecter à Real hard account",
        password: "La langue sombre",
        please_login: "Veuillez vous connecter",
        qh: "indicatif régional",
        qh_dh: "indicatif régional/téléphone",
        re_input_pass: "Retaper le mot de passe",
        register: "Enregistrement",
        register_email: "Entrez votre email",
        register_phone: "Enregistrement mobile",
        reset_mock_account: "Retour au compte de simulation",
        trade_account: "Compte de Trading",
        useful_balance: "Fonds disponibles",
        user_center: "La mine",
        welcome_login: "Bienvenue login",
        welcome_register: "Inscription bienvenue"
    },
    wallet: {
        bind: "Combiné",
        bind_bank_card: "Carte bancaire liée",
        bind_coin_address: "Adresse de monnaie numérique liée",
        not_bind: "Veuillez d'abord configurer un compte de collecte",
        save: "Protection"
    },
    withdraw: {
        amount: "Quantité",
        calc_get: "Prévu",
        no_pay_way: "Les comptes en monnaie française n'ont pas de canaux de paiement.",
        record: "Prise de monnaie record",
        select_withdraw_address: "Veuillez sélectionner l'adresse à appeler",
        send_amount: "Nombre de reçus",
        symbol: "Devises",
        unit: "Unités",
        withdraw_address: "Adresse de prise de monnaie",
        withdraw_coin: "Soumettre un retrait"
    }
}
obj.de = {
    auth: {
        back: "Rückseite",
        finish: "Zertifiziert",
        front: "Vorne",
        id_number: "Pass-/ID-Nummer",
        input_id_num: "Bitte geben Sie Ihre Pass-/ID-Nummer ein",
        input_info: "Bitte füllen Sie Ihre persönlichen Daten aus",
        input_name: "Bitte geben Sie Ihren Namen ein",
        name: "Name",
        upload_back: "Laden Sie die Rückseite Ihres Reisepasses/Zertifikats hoch",
        upload_front: "Laden Sie die Vorderseite Ihres Reisepasses/Zeugnisses hoch",
        upload_idcard: "Bitte laden Sie die Vorder- und Rückseite Ihres Reisepasses/Personalausweises hoch",
        waiting: "Prüfung",
        reject: "ablehnen",
        auth_again: "Neuzertifizierung"
    },
    bank: {
        SWIFT: "SWIFT",
        account: "Zahlungsempfängerkonto",
        address: "Bankadresse",
        bank_name: "Zweigname",
        coin_type: "Empfängerwährung",
        name: "Name der Bank",
        remark: "Bemerkung",
        swift_text: "SWIFT",
        username: "Zahlungsempfänger"
    },
    financial: {
        bank: "Bankkarte",
        bank_charge: "Telegrafendraht",
        charge_amount: "Anzahl der Münzen",
        charge_coin: "Münzen",
        charge_history: "Einlagenbilanz",
        charge_num: "Aufladungsbetrag",
        coin: "Digitale Währung",
        collect_coin_type: "Empfangswährung",
        copy_address: "Adresse kopieren",
        financial_records: "Finanzbuchhaltung",
        input_charge_amount: "Bitte geben Sie die Anzahl der zu belastenden Münzen ein",
        input_charge_num: "Bitte geben Sie den Ladebetrag ein",
        isfinish: "Abgeschlossen",
        isreject: "Abgelehnt",
        link_charge: "Aufladen an der Kette",
        select_network: "Netzwerk auswählen",
        uncheck: "Nicht markiert",
        unuseful: "Ungültig",
        wallet_address: "Wallet-Adresse"
    },
    forgot: {
        input_sms_code: "Bitte geben Sie den Bestätigungscode ein",
        reset: "Passwort zurücksetzen",
        send: "Senden",
        sms_code: "Prüfcode"
    },
    func: {
        billing_detail: "Rechnungsdetails",
        charge_coin: "Kaution",
        invite_friend: "Freunde einladen",
        lang: "Sprache",
        real_auth: "Echtname-Verifizierung",
        reset_password: "Passwort ändern",
        setting: "Einrichtung",
        wallet: "Geldbörse",
        withdraw_coin: "Rücknahme"
    },
    order: {
        all_caution_money: "Aktuelle Marge",
        balance: "Saldo",
        bond_fee: "Marge",
        buy_in: "Kaufen",
        calc_bond_fee: "Geschätzte Marge",
        calc_handle_fee: "Geschätzte Bearbeitungsgebühr",
        can_use: "Vorauszahlung möglich",
        cancel: "Bestellung stornieren",
        close: "Position schließen",
        close_price: "Günstige Lagerpreise",
        confrim_close_order: "Ist es ein billiges Lager?",
        currency: "Transaktionspaare",
        current_price: "Aktueller Preis",
        dealing: "Ausstehende Aufträge",
        detail: "Bestelldetails",
        hand_num: "Viele",
        handle_fee: "Bearbeitungsgebühr",
        hazard_rate: "Risikorate",
        history: "Geschichte",
        holding: "Position halten",
        income: "Gewinn",
        market_price: "Marktpreis",
        open_time: "Öffnungszeiten",
        order_id: "Reservierungsnummer",
        order_price: "Niedrigerer Stückpreis",
        order_type: "Richtung",
        page: "Blatt",
        pay_rate: "Vorauszahlungsverhältnis",
        profit: "Gewinn und Verlust",
        sell_out: "Verkaufen",
        set_stop_price: "Speichern",
        stop_loss: "Set Loss",
        stop_profit: "Gewinn festlegen",
        times: "Multiplikator",
        total: "Nettowert",
        total_ol: "Gesamtergebnis",
        trade_amount: "Viele",
        trading: "Positionen",
        un_deal_order: "Ausstehende Aufträge",
        unit: "Jedes Blatt"
    },
    public: {
        tocust: "Kontaktieren jetzt",
        add: "Hinzufügen",
        cancel: "Abbrechen",
        chat: "Online Service",
        coming_soon: "Kommt bald~",
        confirm: "Bestätigung",
        confirm_del: "Sind Sie sicher, dass Sie löschen?",
        copy: "Kopieren",
        copy_success: "Erfolgreich kopiert",
        delete: "löschen",
        freeze: "Einfrieren",
        history: "Aufzeichnung",
        input_full: "Bitte ausfüllen",
        last_price: "Letzter Preis",
        market: "Trend",
        name: "Name",
        no_data: "Noch keine Daten",
        no_more: "Nicht mehr",
        number: "Menge",
        other: "Sonstige",
        password_not_equal: "Inkonsistenz des Passworts",
        product_list: "Produktliste",
        quotation: "24H Änderung",
        set: "Set",
        setting: "Einstellung",
        status: "Status",
        submit: "Absenden",
        submit_order: "Auftrag abschicken",
        tip: "Prompt",
        upload_pic: "Zertifikat hochladen",
        use: "Verfügbar"
    },
    trade: {
        balance: "Saldo",
        best_price: "Handel zum besten aktuellen Preis",
        buy: "Kaufen",
        buy_low: "Verkürzung",
        buy_price: "Preis",
        buy_up: "go long",
        close_order: "Position schließen",
        closed: "Geschlossen",
        current_entrust: "Vorherige Inbetriebnahme",
        enter_price: "Bitte geben Sie den Preis ein",
        high: "Hoch",
        last_price: "Marktpreis",
        low: "Niedrig",
        no_mult: "Kein Mehrfaches",
        no_mult_config: "Kein Hebel konfiguriert, kann vorübergehend nicht bestellt werden",
        open: "Öffnen",
        operate: "Betrieb",
        order: "Aufträge",
        order_confirm: "Ihre Bestellung wurde bestätigt",
        order_page: "Bestellseite",
        price: "Preis",
        sell: "Verkaufen",
        time: "Datum",
        trade_amount: "Handelslosgröße",
        trade_price: "Transaktionsbetrag",
        type: "Typ",
        volume: "Gesamtvolumen"
    },
    user: {
        account_detail: "Kontodetails",
        announ: "Ankündigung",
        announ_text: "Aus politischen Gründen werden keine Dienstleistungen für Nordkorea, Israel, China, Vanuatu und Kuba angeboten.",
        area_code: "Gebietsvorwahl",
        can_use_asset: "Verfügbare Vermögenswerte",
        com_email: "Beschwerde-E-Mail",
        confirm_logout: "Ob aus dem Register zurückgetreten werden soll",
        confirm_reset: "Ob es zurückgegeben werden soll",
        copy_invite_link: "Einladungslink kopieren",
        dh: "Telefon",
        email: "Postfach",
        forgot_password: "Passwort vergessen",
        h_accept: "Akzeptieren",
        h_second: "second",
        h_timer: "Countdown",
        h_welc: "Willkommen bei der Verwendung",
        input_email: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        input_invite_code: "Bitte geben Sie den Einladungscode ein",
        input_old_password: "Bitte geben Sie Ihr altes Passwort ein",
        input_password: "Bitte geben Sie Ihr Passwort ein",
        input_phone: "Bitte geben Sie Ihre Handynummer ein",
        invite_code: "Einladungscode",
        join_us: "Jetzt beitreten",
        login: "Login",
        login_email: "Geben Sie Ihre E-Mail-Adresse ein",
        login_phone: "Mobile Anmeldung",
        logout: "Abmelden",
        logout_success: "Erfolgreicher Ausstieg",
        mock_account: "Simulationskonto",
        mock_login: "Sie müssen kein Konto eröffnen, erstellen Sie ein Demokonto, um jetzt zu handeln!",
        new_paw: "Neues Passwort",
        new_user: "Neuer Benutzer?",
        only_true_account: "Bitte registrieren Sie sich oder loggen Sie sich in ein echtes Hardkonto ein",
        password: "Passwort",
        please_login: "Bitte loggen Sie sich ein",
        qh: "Vorwahl",
        qh_dh: "Vorwahl/Telefon",
        re_input_pass: "Passwort neu eingeben",
        register: "Registrieren",
        register_email: "Geben Sie Ihre E-Mail-Adresse ein",
        register_phone: "Mobile Registrierung",
        reset_mock_account: "Simuliertes Return-Konto",
        trade_account: "Handelskonto",
        useful_balance: "Verfügbare Mittel",
        user_center: "Meine",
        welcome_login: "Willkommen zum Login",
        welcome_register: "Willkommen zur Anmeldung"
    },
    wallet: {
        bind: "Bindung",
        bind_bank_card: "Verbindliche Bankkarte",
        bind_coin_address: "Adresse der verbindlichen digitalen Währung",
        not_bind: "Bitte legen Sie zuerst ein Inkassokonto an",
        save: "Konservierung"
    },
    withdraw: {
        amount: "Betrag",
        calc_get: "Es wird erwartet, dass",
        no_pay_way: "Es gibt keinen Zahlungskanal für das französische Währungskonto.",
        record: "Aufzeichnung der Münzentnahme",
        select_withdraw_address: "Bitte wählen Sie eine Adresse zum Anrufen",
        send_amount: "Anzahl der Einnahmen",
        symbol: "Währung",
        unit: "Einheit",
        withdraw_address: "Adresse für Münzabhebungen",
        withdraw_coin: "Auszahlung einreichen"
    }
}
obj.ru = {
    auth: {
        back: "Обратная сторона",
        finish: "Подтверждение",
        front: "Положительный",
        id_number: "Номер паспорта / удостоверения личности",
        input_id_num: "Пожалуйста, введите номер вашего паспорта / удостоверения личности",
        input_info: "Пожалуйста, заполните вашу личную информацию.",
        input_name: "Введите ваше имя",
        name: "Имя",
        upload_back: "Загрузить обратную сторону вашего паспорта / сертификата",
        upload_front: "Загрузить ваш паспорт / сертификат спереди",
        upload_idcard: "Пожалуйста, загрузите спереди и сзади ваш паспорт / удостоверение личности",
        waiting: "Ревизия",
        reject: "Отказ",
        auth_again: "Повторная аутентификация"
    },
    bank: {
        SWIFT: "Быстрый",
        account: "Счета получателей",
        address: "Адрес банка",
        bank_name: "Название филиала",
        coin_type: "Валюта поступлений",
        name: "Название банка",
        remark: "Замечания",
        swift_text: "Быстрый",
        username: "Получатель"
    },
    financial: {
        bank: "Банковские карточки",
        bank_charge: "Телеграфные линии",
        charge_amount: "Количество монет",
        charge_coin: "Монеты",
        charge_history: "Учет вкладов",
        charge_num: "Сумма пополнения",
        coin: "Цифровая валюта",
        collect_coin_type: "Валюта поступлений",
        copy_address: "Копировать адрес",
        financial_records: "Финансовая отчетность",
        input_charge_amount: "Пожалуйста, введите количество монет",
        input_charge_num: "Введите сумму пополнения.",
        isfinish: "Завершено",
        isreject: "Отказано",
        link_charge: "Зарядка в цепи.",
        select_network: "Выберите сеть",
        uncheck: "Не выбрано",
        unuseful: "Неверный",
        wallet_address: "Адрес кошелька"
    },
    forgot: {
        input_sms_code: "Введите код проверки.",
        reset: "Сбросить пароль",
        send: "Почта",
        sms_code: "Код проверки"
    },
    func: {
        billing_detail: "Описание счета",
        charge_coin: "Депозит",
        invite_friend: "Пригласить друзей",
        lang: "Язык",
        real_auth: "Проверка подлинного имени",
        reset_password: "Изменить пароль",
        setting: "Учреждения",
        wallet: "Бумажник",
        withdraw_coin: "Снятие"
    },
    order: {
        all_caution_money: "Текущий залог",
        balance: "Равновесие",
        bond_fee: "Границы",
        buy_in: "Покупка",
        calc_bond_fee: "Ожидаемая прибыль",
        calc_handle_fee: "Прогнозируемые расходы",
        can_use: "Возможен досрочный платеж",
        cancel: "Отменить заказ",
        close: "Закрыть позицию",
        close_price: "Складские цены недорого",
        confrim_close_order: "Это дешевый склад?",
        currency: "Дела обрабатываются правильно.",
        current_price: "Текущая цена",
        dealing: "Необработанные заказы",
        detail: "Подробная информация о заказе",
        hand_num: "Лотс",
        handle_fee: "Плата за услуги",
        hazard_rate: "Коэффициент риска",
        history: "История",
        holding: "Хранить склад",
        income: "Прибыль",
        market_price: "Рыночные цены",
        open_time: "Время открытия",
        order_id: "Номер бронирования",
        order_price: "Более низкая цена",
        order_type: "Направление",
        page: "Простыни",
        pay_rate: "Доля авансов",
        profit: "Убытки и убытки",
        sell_out: "Продажа",
        set_stop_price: "Спасение",
        stop_loss: "Установленные потери",
        stop_profit: "Установить прибыль",
        times: "Множитель",
        total: "Чистая стоимость",
        total_ol: "Общая сумма прибылей и убытков",
        trade_amount: "Лотс",
        trading: "Местоположение",
        un_deal_order: "Необработанные заказы",
        unit: "Каждый лист бумаги"
    },
    public: {
        tocust: "Немедленно свяжитесь",
        add: "Добавить",
        cancel: "Отменить",
        chat: "Онлайновые услуги",
        coming_soon: "Скоро запустят",
        confirm: "Подтверждение",
        confirm_del: "Вы уверены, что хотите удалить?",
        copy: "Копирование",
        copy_success: "Копировать успешно",
        delete: "Исключить",
        freeze: "Замораживание",
        history: "Запись",
        input_full: "Заполните, пожалуйста.",
        last_price: "Предыдущая цена",
        market: "Тенденции",
        name: "Имя",
        no_data: "Пока нет данных.",
        no_more: "Больше нет",
        number: "А",
        other: "Кроме того",
        password_not_equal: "Пароль не совпадает.",
        product_list: "Список продуктов",
        quotation: "24H Изменить",
        set: "Настройка",
        setting: "Справочная информация",
        status: "Статус",
        submit: "Представлено",
        submit_order: "Представление заказов",
        tip: "Стимулирование",
        upload_pic: "Загружается сертификат",
        use: "Доступные"
    },
    trade: {
        balance: "Равновесие",
        best_price: "Торговля по лучшей текущей цене",
        buy: "Покупка",
        buy_low: "Короткое замыкание",
        buy_price: "Цены",
        buy_up: "Больше.",
        close_order: "Закрыть позицию",
        closed: "Закрыть",
        current_entrust: "Предварительная отладка",
        enter_price: "Пожалуйста, введите цену.",
        high: "Высокий",
        last_price: "Рыночные цены",
        low: "Низкий",
        no_mult: "Нет кратного",
        no_mult_config: "Не настроен рычаг, временно невозможно сделать заказ",
        open: "Открыть",
        operate: "Деятельность",
        order: "Заказы",
        order_confirm: "Ваш заказ подтвержден.",
        order_page: "Страница заказа",
        price: "Цены",
        sell: "Продажа",
        time: "Дата",
        trade_amount: "Размер транзакции",
        trade_price: "Сумма сделки",
        type: "Тип",
        volume: "Общий объем"
    },
    user: {
        account_detail: "Сведения об учетной записи",
        announ: "объявление",
        announ_text: "По политическим причинам услуги не предоставляются Северной Корее, Израилю, Китаю, Вануату и Кубе.",
        area_code: "Региональный код",
        can_use_asset: "Имеющиеся активы",
        com_email: "Жалоба по электронной почте",
        confirm_logout: "Выход из регистрации",
        confirm_reset: "Возврат товара",
        copy_invite_link: "Копировать ссылку приглашения",
        dh: "телефон",
        email: "Почтовый ящик",
        forgot_password: "Забыть пароль",
        h_accept: "Принять",
        h_second: "секунда",
        h_timer: "обратный отсчет",
        h_welc: "Добро пожаловать в использование",
        input_email: "Введите адрес электронной почты",
        input_invite_code: "Введите код приглашения.",
        input_old_password: "Введите свой старый пароль.",
        input_password: "Введите пароль.",
        input_phone: "Пожалуйста, введите номер вашего мобильного.",
        invite_code: "Код приглашения",
        join_us: "Присоединяйтесь немедленно.",
        login: "Регистрация",
        login_email: "Введите свою электронную почту",
        login_phone: "Мобильный вход",
        logout: "Списание",
        logout_success: "Успешный выход",
        mock_account: "Имитационный счет",
        mock_login: "Не нужно открывать счет, создайте аналоговый счет для торговли!",
        new_paw: "Новый пароль",
        new_user: "Новые пользователи?",
        only_true_account: "Пожалуйста, зарегистрируйтесь или войдите в реальный жесткий аккаунт.",
        password: "Язык",
        please_login: "Пожалуйста, войдите",
        qh: "код города",
        qh_dh: "код города/телефон",
        re_input_pass: "Введите пароль",
        register: "Регистрация",
        register_email: "Введите свою электронную почту",
        register_phone: "Регистрация мобильного телефона",
        reset_mock_account: "Вернуться к учётной записи моделирования",
        trade_account: "Счета операций",
        useful_balance: "Имеющиеся средства",
        user_center: "Минералы",
        welcome_login: "Добро пожаловать в систему",
        welcome_register: "Добро пожаловать на регистрацию"
    },
    wallet: {
        bind: "Сочетание",
        bind_bank_card: "Привязка банковских карт",
        bind_coin_address: "Адрес привязанной цифровой валюты",
        not_bind: "Сначала откройте счет.",
        save: "Защита"
    },
    withdraw: {
        amount: "Количество",
        calc_get: "Ожидается",
        no_pay_way: "Банковские счета не имеют платежных каналов.",
        record: "Монетные записи",
        select_withdraw_address: "Выберите адрес для вызова.",
        send_amount: "Количество квитанций",
        symbol: "Валюта",
        unit: "Модуль",
        withdraw_address: "Денежный адрес",
        withdraw_coin: "Представление средств"
    }
}
obj.spa = {
    auth: {
        back: "Reverso",
        finish: "Confirmado",
        front: "Frontal",
        id_number: "Número de pasaporte / DNI",
        input_id_num: "Introduzca su pasaporte / número de identificación",
        input_info: "Por favor, rellene su información personal.",
        input_name: "Introduzca su nombre",
        name: "Nombre",
        upload_back: "Suba el reverso de su pasaporte / certificado",
        upload_front: "Suba la parte delantera de su pasaporte / certificado",
        upload_idcard: "Por favor, suba la parte delantera y trasera de su pasaporte / tarjeta de identificación.",
        waiting: "Auditoría",
        reject: "Rechazar",
        auth_again: "Volver a certificar"
    },
    bank: {
        SWIFT: "Rápido",
        account: "Cuenta del beneficiario",
        address: "Dirección bancaria",
        bank_name: "Nombre de la sucursal",
        coin_type: "Moneda de cobro",
        name: "Nombre del Banco",
        remark: "Comentarios",
        swift_text: "Rápido",
        username: "Beneficiario"
    },
    financial: {
        bank: "Tarjeta bancaria",
        bank_charge: "Línea telegráfica",
        charge_amount: "Número de monedas",
        charge_coin: "Monedas",
        charge_history: "Registro de depósitos",
        charge_num: "Cantidad recargada",
        coin: "Moneda digital",
        collect_coin_type: "Moneda de cobro",
        copy_address: "Copiar dirección",
        financial_records: "Registros financieros",
        input_charge_amount: "Introduzca el número de monedas a recargar",
        input_charge_num: "Introduzca el monto de recarga",
        isfinish: "Completado",
        isreject: "Rechazada",
        link_charge: "Cargar en la cadena",
        select_network: "Selección de redes",
        uncheck: "No seleccionado",
        unuseful: "Inválido",
        wallet_address: "Dirección de la billetera"
    },
    forgot: {
        input_sms_code: "Por favor, introduzca el Código de verificación",
        reset: "Restablecer la contraseña",
        send: "Correo",
        sms_code: "Código de verificación"
    },
    func: {
        billing_detail: "Detalles de la factura",
        charge_coin: "Depósito",
        invite_friend: "Invitar a amigos",
        lang: "Idiomas",
        real_auth: "Verificación del sistema de nombre real",
        reset_password: "Modificar la contraseña",
        setting: "Instituciones",
        wallet: "Billetera",
        withdraw_coin: "Retirada"
    },
    order: {
        all_caution_money: "Margen actual",
        balance: "Equilibrio",
        bond_fee: "Borde",
        buy_in: "Compra",
        calc_bond_fee: "Beneficios previstos",
        calc_handle_fee: "Comisiones previstas",
        can_use: "Se puede reembolsar por adelantado",
        cancel: "Cancelación del pedido",
        close: "Posición de cierre",
        close_price: "El precio del almacén es barato",
        confrim_close_order: "¿¿ es un almacén barato?",
        currency: "Transacciones",
        current_price: "Precios actuales",
        dealing: "Pedidos pendientes",
        detail: "Detalles del pedido",
        hand_num: "Lotz",
        handle_fee: "Comisiones",
        hazard_rate: "Tasa de riesgo",
        history: "Historia",
        holding: "Posiciones",
        income: "Ganancias",
        market_price: "Precio de mercado",
        open_time: "Horario de apertura",
        order_id: "Número de reserva",
        order_price: "Precio unitario más bajo",
        order_type: "Dirección",
        page: "Sábanas",
        pay_rate: "Tasa de anticipos",
        profit: "Ganancias y pérdidas",
        sell_out: "Vender",
        set_stop_price: "Salvar",
        stop_loss: "Establecer pérdidas",
        stop_profit: "Establecer beneficios",
        times: "Multiplicador",
        total: "Valor neto",
        total_ol: "Ganancias y pérdidas totales",
        trade_amount: "Lotz",
        trading: "Posición",
        un_deal_order: "Pedidos pendientes",
        unit: "Cada hoja de papel"
    },
    public: {
        tocust: "Contacto inmediato",
        add: "Añadir",
        cancel: "Cancelación",
        chat: "Servicios en línea",
        coming_soon: "Está a punto de lanzarse.",
        confirm: "Confirmación",
        confirm_del: "¿Estás seguro de que quieres eliminarlo?",
        copy: "Copiar",
        copy_success: "Copia exitosa",
        delete: "Eliminar",
        freeze: "Congelación",
        history: "Registro",
        input_full: "Por favor, rellene",
        last_price: "El último precio",
        market: "Tendencias",
        name: "Nombre",
        no_data: "Aún no hay datos",
        no_more: "Ya no",
        number: "Cantidad",
        other: "Además",
        password_not_equal: "Contraseña inconsistente",
        product_list: "Lista de productos",
        quotation: "Cambio 24h",
        set: "Configuración",
        setting: "Antecedentes",
        status: "Estatus",
        submit: "Presentación",
        submit_order: "Presentación de pedidos",
        tip: "Promover",
        upload_pic: "Se está cargando el certificado",
        use: "Disponible"
    },
    trade: {
        balance: "Equilibrio",
        best_price: "Negociar al mejor precio actual",
        buy: "Compra",
        buy_low: "Cortocircuito",
        buy_price: "Precio",
        buy_up: "Hacer más",
        close_order: "Posición de cierre",
        closed: "Cierre",
        current_entrust: "Preprocesamiento",
        enter_price: "Por favor, introduzca el precio",
        high: "Alto",
        last_price: "Precio de mercado",
        low: "Bajo",
        no_mult: "No hay múltiplos",
        no_mult_config: "No se ha configurado el apalancamiento y no se puede hacer un pedido por el momento.",
        open: "Abrir",
        operate: "Actividades",
        order: "Pedidos",
        order_confirm: "Su pedido ha sido confirmado",
        order_page: "Página de pedidos",
        price: "Precio",
        sell: "Vender",
        time: "Fecha",
        trade_amount: "Tamaño del lote de transacciones",
        trade_price: "Importe de la transacción",
        type: "Tipo",
        volume: "Volumen total"
    },
    user: {
        account_detail: "Detalles de la cuenta",
        announ: "anuncio",
        announ_text: "Por razones de política, no se brindan servicios a Corea del Norte, Israel, China, Vanuatu y Cuba.",
        area_code: "Código de área",
        can_use_asset: "Activos disponibles",
        com_email: "Correo electrónico de queja",
        confirm_logout: "Si se retira del registro",
        confirm_reset: "Si devolver o no",
        copy_invite_link: "Copiar enlace de invitación",
        dh: "teléfono",
        email: "Buzón postal",
        forgot_password: "Olvida la contraseña",
        h_accept: "Aceptar",
        h_second: "segundo",
        h_timer: "cuenta regresiva",
        h_welc: "Bienvenido a usar",
        input_email: "Introduzca su dirección de correo electrónico",
        input_invite_code: "Por favor, introduzca el Código de invitación.",
        input_old_password: "Introduzca su antigua contraseña",
        input_password: "Introduzca su contraseña",
        input_phone: "Introduzca su número de teléfono móvil",
        invite_code: "Código de invitación",
        join_us: "Únete de inmediato",
        login: "Iniciar sesión",
        login_email: "Introduzca su correo electrónico",
        login_phone: "Inicio de sesión del teléfono móvil",
        logout: "Cancelación",
        logout_success: "Salida exitosa",
        mock_account: "Cuenta simulada",
        mock_login: "¡¡ no es necesario abrir una cuenta para crear una cuenta simulada para realizar transacciones!",
        new_paw: "Nueva contraseña",
        new_user: "¿Nuevos usuarios?",
        only_true_account: "Por favor, registre o inicie sesión en una cuenta dura real",
        password: "Palabras clave",
        please_login: "Por favor, inicie sesión",
        qh: "código de área",
        qh_dh: "código de área/teléfono",
        re_input_pass: "Volver a escribir la contraseña",
        register: "Registro",
        register_email: "Introduzca su correo electrónico",
        register_phone: "Registro de teléfonos móviles",
        reset_mock_account: "Volver a la cuenta simulada",
        trade_account: "Cuenta de transacción",
        useful_balance: "Fondos disponibles",
        user_center: "Mineral",
        welcome_login: "Bienvenido a iniciar sesión",
        welcome_register: "Bienvenido a registrarse"
    },
    wallet: {
        bind: "Combinar",
        bind_bank_card: "Tarjeta bancaria vinculada",
        bind_coin_address: "Dirección de moneda digital vinculada",
        not_bind: "Por favor, establezca primero la cuenta de cobro",
        save: "Protección"
    },
    withdraw: {
        amount: "Cantidad",
        calc_get: "Se espera",
        no_pay_way: "La cuenta en moneda francesa no tiene canales de pago.",
        record: "Registro de retiro de moneda",
        select_withdraw_address: "Por favor, elija la dirección a llamar",
        send_amount: "Número de recibos",
        symbol: "Moneda",
        unit: "Unidad",
        withdraw_address: "Dirección de recogida de moneda",
        withdraw_coin: "Presentación de retiros"
    }
}
obj.pt = {
    auth: {
        back: "Lado inverso",
        finish: "Certificado",
        front: "Frente",
        id_number: "Passaporte/número de identificação",
        input_id_num: "Introduza o seu passaporte/número de identificação",
        input_info: "Preencha as suas informações pessoais",
        input_name: "Por favor indique o seu nome",
        name: "Nome",
        upload_back: "Carregue o verso do seu passaporte/certificado",
        upload_front: "Carregue a frente do seu passaporte/certificado",
        upload_idcard: "Por favor, carregue a frente e verso do seu passaporte / ID",
        waiting: "Auditoria",
        reject: "odmów",
        auth_again: "Ponowna certyfikacja"
    },
    bank: {
        SWIFT: "SWIFT",
        account: "Conta de beneficiário",
        address: "Endereço Bancário",
        bank_name: "Nome da filial",
        coin_type: "Moeda beneficiária",
        name: "Nome do Banco",
        remark: "Observação",
        swift_text: "SWIFT",
        username: "Beneficiário"
    },
    financial: {
        bank: "Cartão Bancário",
        bank_charge: "Fio telégrafo",
        charge_amount: "Número de moedas",
        charge_coin: "Moedas",
        charge_history: "Registo de depósito",
        charge_num: "Montante da recarga",
        coin: "Moeda Digital",
        collect_coin_type: "Moeda do recibo",
        copy_address: "Copiar o Endereço",
        financial_records: "Registos Financeiros",
        input_charge_amount: "Introduza o número de moedas a cobrar",
        input_charge_num: "Indique a quantidade de recarga",
        isfinish: "Concluído",
        isreject: "Rejeitado",
        link_charge: "Recarga na corrente",
        select_network: "Seleccionar a Rede",
        uncheck: "Desconhecido",
        unuseful: "Inválido",
        wallet_address: "Endereço da Carteira"
    },
    forgot: {
        input_sms_code: "Introduza o código de verificação",
        reset: "Repor a Senha",
        send: "Enviar",
        sms_code: "Código de verificação"
    },
    func: {
        billing_detail: "Detalhes da facturação",
        charge_coin: "Depósito",
        invite_friend: "Convidar Amigos",
        lang: "Língua",
        real_auth: "Verificação de Nome Real",
        reset_password: "Mudar a Senha",
        setting: "estabelecimento",
        wallet: "Carteira",
        withdraw_coin: "Retirada"
    },
    order: {
        all_caution_money: "Margem Corrente",
        balance: "Saldo",
        bond_fee: "Margem",
        buy_in: "Comprar",
        calc_bond_fee: "Margem estimada",
        calc_handle_fee: "Taxa de manuseamento estimada",
        can_use: "Pré-pagamento disponível",
        cancel: "Cancelar encomenda",
        close: "Fechar a posição",
        close_price: "Preço de armazém barato",
        confrim_close_order: "É um armazém barato",
        currency: "Pares de transacções",
        current_price: "Preço actual",
        dealing: "Ordens pendentes",
        detail: "Detalhes da encomenda",
        hand_num: "Lotes",
        handle_fee: "Taxa de tratamento",
        hazard_rate: "Taxa de risco",
        history: "História",
        holding: "Posição de posição",
        income: "lucro",
        market_price: "Preço de mercado",
        open_time: "Horário aberto",
        order_id: "Número da reserva",
        order_price: "Preço unitário inferior",
        order_type: "direcção",
        page: "Folha",
        pay_rate: "Taxa de pré-pagamento",
        profit: "Lucros e perdas",
        sell_out: "Vender",
        set_stop_price: "Gravar",
        stop_loss: "Definir Perda",
        stop_profit: "Definir o Lucro",
        times: "Multiplicador",
        total: "Valor líquido",
        total_ol: "Total dos resultados",
        trade_amount: "Lotes",
        trading: "Posições",
        un_deal_order: "Ordens pendentes",
        unit: "Cada folha"
    },
    public: {
        tocust: "Contato Agora",
        add: "Adicionar",
        cancel: "Cancelar",
        chat: "Serviço Online",
        coming_soon: "Em breve ~",
        confirm: "confirmação",
        confirm_del: "Tem a certeza de apagar?",
        copy: "Copiar",
        copy_success: "Copiado com sucesso",
        delete: "apagar",
        freeze: "Congelar",
        history: "Gravar",
        input_full: "Complete por favor",
        last_price: "Último Preço",
        market: "Tendência",
        name: "Nome",
        no_data: "Ainda não existem dados",
        no_more: "Não mais",
        number: "Quantidade",
        other: "Outros",
        password_not_equal: "Inconferência da senha",
        product_list: "Lista de Produtos",
        quotation: "Alteração 24H",
        set: "Definir",
        setting: "Configuração",
        status: "Estado",
        submit: "Enviar",
        submit_order: "Enviar Ordem",
        tip: "Pedir",
        upload_pic: "Carregar o Certificado",
        use: "Disponível"
    },
    trade: {
        balance: "Saldo",
        best_price: "Negociação ao melhor preço atual",
        buy: "Comprar",
        buy_low: "Encerramento",
        buy_price: "Preço",
        buy_up: "Vai longe",
        close_order: "Fechar a posição",
        closed: "Fechado",
        current_entrust: "Comissionamento prévio",
        enter_price: "Introduza o preço",
        high: "Alto",
        last_price: "Preço de mercado",
        low: "Baixo",
        no_mult: "Sem múltiplo",
        no_mult_config: "Nenhuma alavanca configurada, incapaz de fazer um pedido temporariamente",
        open: "Abrir",
        operate: "Operação",
        order: "Ordens",
        order_confirm: "A sua encomenda foi confirmada.",
        order_page: "Página de Encomenda",
        price: "preço",
        sell: "Vender",
        time: "Data",
        trade_amount: "Dimensão do lote de negociação",
        trade_price: "Montante da transacção",
        type: "Tipo",
        volume: "Volume total"
    },
    user: {
        account_detail: "Detalhes da Conta",
        announ: "anúncio",
        announ_text: "Devido a razões políticas, os serviços não são fornecidos para a Coreia do Norte, Israel, China, Vanuatu e Cuba.",
        area_code: "Código da área",
        can_use_asset: "Activos disponíveis",
        com_email: "E-mail de reclamação",
        confirm_logout: "Retirada do registo",
        confirm_reset: "Se deve devolvê-lo",
        copy_invite_link: "Copiar a Ligação de Convites",
        dh: "telefone",
        email: "Caixa Postal",
        forgot_password: "Esqueceu- se da senha",
        h_accept: "Aceitar",
        h_second: "segundo",
        h_timer: "contagem regressiva",
        h_welc: "Bem-vindo ao uso",
        input_email: "Introduza o seu endereço de e-mail",
        input_invite_code: "Indique por favor o código do convite",
        input_old_password: "Por favor introduza a sua senha antiga",
        input_password: "Por favor introduza a sua senha",
        input_phone: "Por favor, introduza o seu número de telemóvel",
        invite_code: "Código do Convite",
        join_us: "Junte-se Agora",
        login: "Login",
        login_email: "Indique o seu E- mail",
        login_phone: "Ligação Móvel",
        logout: "Sair",
        logout_success: "Saída bem sucedida",
        mock_account: "Conta de Simulação",
        mock_login: "Não há necessidade de abrir uma conta, crie uma conta demo para negociar agora!",
        new_paw: "Nova senha",
        new_user: "Novo Utilizador?",
        only_true_account: "Por favor, registre-se ou faça login em uma conta real",
        password: "Senha",
        please_login: "Por favor, faça login",
        qh: "código de área",
        qh_dh: "código de área/telefone",
        re_input_pass: "Digite novamente a senha",
        register: "Registar",
        register_email: "Indique o seu E- mail",
        register_phone: "Registo móvel",
        reset_mock_account: "Devolver a conta simulada",
        trade_account: "Conta de negociação",
        useful_balance: "Fundos disponíveis",
        user_center: "Minha",
        welcome_login: "Bem- vindo ao login",
        welcome_register: "Bem-vindo ao registo"
    },
    wallet: {
        bind: "ligação",
        bind_bank_card: "Cartão bancário vinculativo",
        bind_coin_address: "Ligar o endereço da moeda digital",
        not_bind: "Por favor, crie uma conta de cobrança primeiro",
        save: "preservação"
    },
    withdraw: {
        amount: "Montante",
        calc_get: "Espera-se que",
        no_pay_way: "Não há canal de pagamento para a conta em moeda francesa.",
        record: "Registo de retirada de moedas",
        select_withdraw_address: "Seleccione por favor um endereço para ligar",
        send_amount: "Número de receitas",
        symbol: "Moeda",
        unit: "Unidade",
        withdraw_address: "Endereço para retirada de moedas",
        withdraw_coin: "Apresentar a retirada"
    }
}
obj.ar = {
    auth: {
        back: "عكس",
        finish: "أكّد",
        front: "أمامي",
        id_number: "رقم الجواز / الهوية",
        input_id_num: "الرجاء إدخال رقم جواز السفر / الهوية",
        input_info: "يرجى ملء المعلومات الشخصية الخاصة بك",
        input_name: "من فضلك أدخل اسمك",
        name: "الإسم",
        upload_back: "تحميل جواز سفرك / الشهادة على الجانب الآخر",
        upload_front: "تحميل جواز سفرك / الشهادة مباشرة",
        upload_idcard: "يرجى تحميل جواز سفرك / بطاقة الهوية إلى الأمام والخلف",
        waiting: "مراجعة الحسابات",
        reject: "رفض",
        auth_again: "التوثيق مرة أخرى"
    },
    bank: {
        SWIFT: "سريع",
        account: "حساب المستفيد",
        address: "عنوان البنك",
        bank_name: "اسم الفرع",
        coin_type: "جمع العملة",
        name: "اسم البنك",
        remark: "تعليق .",
        swift_text: "سريع",
        username: "المستفيد"
    },
    financial: {
        bank: "بطاقة مصرفية",
        bank_charge: "سلك التلغراف",
        charge_amount: "عدد القطع النقدية",
        charge_coin: "عملة معدنية",
        charge_history: "إيداع سجل",
        charge_num: "شحن المبلغ",
        coin: "العملة الرقمية",
        collect_coin_type: "جمع العملة",
        copy_address: "نسخ العنوان",
        financial_records: "السجلات المالية",
        input_charge_amount: "من فضلك أدخل عدد القطع النقدية التي تريد شحن",
        input_charge_num: "من فضلك أدخل مبلغ الشحن",
        isfinish: "انتهى",
        isreject: "رفض",
        link_charge: "شحن على سلسلة",
        select_network: "اختيار الشبكة",
        uncheck: "غير محدد",
        unuseful: "باطل",
        wallet_address: "عنوان المحفظة"
    },
    forgot: {
        input_sms_code: "الرجاء إدخال رمز التحقق",
        reset: "إعادة تعيين كلمة المرور",
        send: "البريدية",
        sms_code: "رمز التحقق"
    },
    func: {
        billing_detail: "تفاصيل الفواتير",
        charge_coin: "إيداع",
        invite_friend: "دعوة الأصدقاء",
        lang: "لغة",
        real_auth: "التحقق من الاسم الحقيقي",
        reset_password: "تعديل كلمة المرور",
        setting: "المؤسسات",
        wallet: "محفظة",
        withdraw_coin: "سحب"
    },
    order: {
        all_caution_money: "الهامش الحالي",
        balance: "التوازن",
        bond_fee: "حافة",
        buy_in: "شراء",
        calc_bond_fee: "الربح المتوقع",
        calc_handle_fee: "الرسوم المقدرة",
        can_use: "السداد المبكر",
        cancel: "إلغاء النظام",
        close: "موقف مغلقة",
        close_price: "مستودع رخيصة",
        confrim_close_order: "هذا هو مستودع رخيصة",
        currency: "معاملة الزوج",
        current_price: "السعر الحالي",
        dealing: "من أجل تجهيز",
        detail: "تفاصيل النظام",
        hand_num: "لوتز",
        handle_fee: "رسوم المناولة",
        hazard_rate: "معدل المخاطرة",
        history: "تاريخ",
        holding: "موقف",
        income: "ربح",
        market_price: "سعر السوق",
        open_time: "ساعات العمل",
        order_id: "رقم الحجز",
        order_price: "انخفاض سعر الوحدة",
        order_type: "الاتجاه",
        page: "ملاءات السرير",
        pay_rate: "نسبة السلف",
        profit: "الربح والخسارة",
        sell_out: "بيع .",
        set_stop_price: "أنقذ",
        stop_loss: "وضع الخسارة",
        stop_profit: "تحديد الربح",
        times: "مضاعف",
        total: "صافي القيمة",
        total_ol: "مجموع الربح والخسارة",
        trade_amount: "لوتز",
        trading: "موقع",
        un_deal_order: "من أجل تجهيز",
        unit: "كل ورقة"
    },
    public: {
        tocust: "الاتصال فورا",
        add: "إضافة .",
        cancel: "ألغى",
        chat: "خدمات الانترنت",
        coming_soon: "قريبا",
        confirm: "تأكيد",
        confirm_del: "هل أنت متأكد أنك تريد حذف ؟",
        copy: "نسخ",
        copy_success: "نسخة ناجحة",
        delete: "حذف",
        freeze: "تجمد",
        history: "سجل",
        input_full: "يرجى ملء",
        last_price: "السعر الأخير",
        market: "الاتجاه",
        name: "الإسم",
        no_data: "لا توجد بيانات",
        no_more: "لم يعد",
        number: "كمية",
        other: "بالإضافة إلى ذلك",
        password_not_equal: "كلمة السر غير متناسقة",
        product_list: "قائمة المنتجات",
        quotation: "تغيير",
        set: "ضبط",
        setting: "معلومات أساسية",
        status: "مركز",
        submit: "قدم",
        submit_order: "تقديم طلب",
        tip: "حث",
        upload_pic: "تحميل الشهادة",
        use: "متاح"
    },
    trade: {
        balance: "التوازن",
        best_price: "التجارة في أفضل الأسعار الحالية",
        buy: "شراء",
        buy_low: "دائرة كهربائية قصيرة",
        buy_price: "الأسعار",
        buy_up: "القيام بالكثير",
        close_order: "موقف مغلقة",
        closed: "غلق",
        current_entrust: "قبل التصحيح",
        enter_price: "من فضلك أدخل السعر",
        high: "عالية",
        last_price: "سعر السوق",
        low: "منخفض",
        no_mult: "أي مضاعفات",
        no_mult_config: "لم يتم تكوين رافعة ، لا يمكن وضع النظام مؤقتا",
        open: "فتح .",
        operate: "النشاطات",
        order: "أمر",
        order_confirm: "تم تأكيد طلبك",
        order_page: "صفحة النظام",
        price: "الأسعار",
        sell: "بيع .",
        time: "تواريخ",
        trade_amount: "حجم الصفقة",
        trade_price: "مبلغ الصفقة",
        type: "نوع .",
        volume: "مجموع حجم"
    },
    user: {
        account_detail: "تفاصيل الحساب",
        announ: "إعلان",
        announ_text: "لأسباب تتعلق بالسياسة ، لا يتم تقديم الخدمات إلى كوريا الشمالية وإسرائيل والصين وفانواتو وكوبا.",
        area_code: "رمز المنطقة",
        can_use_asset: "الأصول المتاحة",
        com_email: "صندوق بريد الشكوى",
        confirm_logout: "خروج التسجيل",
        confirm_reset: "العودة",
        copy_invite_link: "نسخ دعوة الرابط",
        dh: "هاتف",
        email: "صندوق بريد",
        forgot_password: "نسيت كلمة السر",
        h_accept: "قبول",
        h_second: "الثانية",
        h_timer: "العد التنازلي",
        h_welc: "مرحبًا بك في الاستخدام",
        input_email: "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك",
        input_invite_code: "الرجاء إدخال رمز الدعوة",
        input_old_password: "الرجاء إدخال كلمة السر القديمة",
        input_password: "الرجاء إدخال كلمة السر الخاصة بك",
        input_phone: "من فضلك أدخل رقم هاتفك",
        invite_code: "رمز الدعوة",
        join_us: "الانضمام فورا",
        login: "سجل",
        login_email: "أدخل البريد الإلكتروني الخاص بك",
        login_phone: "الهاتف المحمول تسجيل الدخول",
        logout: "شطب",
        logout_success: "خروج ناجح",
        mock_account: "حساب محاكاة",
        mock_login: "لا تحتاج إلى فتح حساب ، إنشاء حساب محاكاة يمكن إجراء المعاملات !",
        new_paw: "كلمة السر الجديدة",
        new_user: "مستخدم جديد ؟",
        only_true_account: "الرجاء تسجيل الدخول أو تسجيل الدخول إلى الحساب الثابت الحقيقي",
        password: "كلمة السر",
        please_login: "الرجاء تسجيل الدخول",
        qh: "رمز المنطقة",
        qh_dh: "رمز المنطقة / الهاتف",
        re_input_pass: "إعادة كتابة كلمة المرور",
        register: "التسجيل",
        register_email: "أدخل البريد الإلكتروني الخاص بك",
        register_phone: "تسجيل الهاتف المحمول",
        reset_mock_account: "العودة إلى حساب محاكاة",
        trade_account: "حساب التداول",
        useful_balance: "الأموال المتاحة",
        user_center: "ركاز",
        welcome_login: "مرحبا بكم في تسجيل الدخول",
        welcome_register: "مرحبا بكم في التسجيل"
    },
    wallet: {
        bind: "ملزم",
        bind_bank_card: "ربط بطاقة البنك",
        bind_coin_address: "ربط عنوان العملة الرقمية",
        not_bind: "يرجى إعداد حساب الدفع",
        save: "حماية"
    },
    withdraw: {
        amount: "الكمية",
        calc_get: "توقع",
        no_pay_way: "لا توجد قنوات الدفع في حساب العملة الفرنسية .",
        record: "سجل العملة",
        select_withdraw_address: "الرجاء اختيار العنوان الذي تريد الاتصال",
        send_amount: "استلام الكمية",
        symbol: "العملة",
        unit: "وحدة",
        withdraw_address: "عنوان العملة",
        withdraw_coin: "تقديم سحب"
    }
}
obj.it = {
    auth: {
        back: "Lato inverso",
        finish: "Certificato",
        front: "Anteriore",
        id_number: "Passaporto/numero d'identità",
        input_id_num: "Inserisci il tuo passaporto/numero d'identità",
        input_info: "Compila i tuoi dati personali",
        input_name: "Inserisci il tuo nome",
        name: "Nome",
        upload_back: "Carica il retro del passaporto/certificato",
        upload_front: "Carica la parte anteriore del passaporto/certificato",
        upload_idcard: "Si prega di caricare la parte anteriore e posteriore del passaporto / documento d'identità",
        waiting: "Audit",
        reject: "rifiuti",
        auth_again: "Ricertificazione"
    },
    bank: {
        SWIFT: "SWIFT",
        account: "Conto beneficiario",
        address: "Indirizzo bancario",
        bank_name: "Nome della filiale",
        coin_type: "Valuta beneficiario",
        name: "Nome della banca",
        remark: "Osservazione",
        swift_text: "SWIFT",
        username: "Beneficiario"
    },
    financial: {
        bank: "Carta bancaria",
        bank_charge: "Filo telegrafico",
        charge_amount: "Numero di monete",
        charge_coin: "Monete",
        charge_history: "Registrazione dei depositi",
        charge_num: "Importo di ricarica",
        coin: "Moneta digitale",
        collect_coin_type: "Moneta di ricezione",
        copy_address: "Copia indirizzo",
        financial_records: "Documenti finanziari",
        input_charge_amount: "Inserisci il numero di monete da addebitare",
        input_charge_num: "Inserisci l'importo della ricarica",
        isfinish: "Completato",
        isreject: "Respinto",
        link_charge: "Ricarica sulla catena",
        select_network: "Seleziona rete",
        uncheck: "Non controllato",
        unuseful: "Non valido",
        wallet_address: "Indirizzo portafoglio"
    },
    forgot: {
        input_sms_code: "Inserisci il codice di verifica",
        reset: "Ripristina password",
        send: "Invia",
        sms_code: "Codice di verifica"
    },
    func: {
        billing_detail: "Dettagli di fatturazione",
        charge_coin: "Deposito",
        invite_friend: "Invita amici",
        lang: "Lingua",
        real_auth: "Verifica del nome reale",
        reset_password: "Cambia password",
        setting: "stabilimento",
        wallet: "Portafoglio",
        withdraw_coin: "Ritiro"
    },
    order: {
        all_caution_money: "Margine attuale",
        balance: "Saldo",
        bond_fee: "Margine",
        buy_in: "Compra",
        calc_bond_fee: "Margine stimato",
        calc_handle_fee: "Tassa di gestione stimata",
        can_use: "Pagamento anticipato disponibile",
        cancel: "Annulla ordine",
        close: "Posizione di chiusura",
        close_price: "Prezzo a buon mercato del magazzino",
        confrim_close_order: "E' un magazzino economico?",
        currency: "Coppie di transazioni",
        current_price: "Prezzo corrente",
        dealing: "Ordini pendenti",
        detail: "Dettagli dell'ordine",
        hand_num: "Lotti",
        handle_fee: "Tassa di gestione",
        hazard_rate: "Tasso di rischio",
        history: "Storia",
        holding: "Mantenimento della posizione",
        income: "profitto",
        market_price: "Prezzo di mercato",
        open_time: "Orario aperto",
        order_id: "Numero di prenotazione",
        order_price: "Prezzo unitario inferiore",
        order_type: "direzione",
        page: "Foglio",
        pay_rate: "Rapporto di pagamento anticipato",
        profit: "Utile e perdite",
        sell_out: "Vendi",
        set_stop_price: "Salva",
        stop_loss: "Imposta perdita",
        stop_profit: "Imposta profitto",
        times: "Moltiplicatore",
        total: "Valore netto",
        total_ol: "utile e perdita totale",
        trade_amount: "Lotti",
        trading: "Posizioni",
        un_deal_order: "Ordini pendenti",
        unit: "Ogni foglio"
    },
    public: {
        tocust: "Contattaci ora",
        add: "Aggiungi",
        cancel: "Annulla",
        chat: "Servizio online",
        coming_soon: "Coming soon~",
        confirm: "conferma",
        confirm_del: "Sei sicuro di cancellare?",
        copy: "Copia",
        copy_success: "Copiata con successo",
        delete: "elimina",
        freeze: "Ferma",
        history: "Registra",
        input_full: "Compilare",
        last_price: "Ultimo prezzo",
        market: "Tendenza",
        name: "Nome",
        no_data: "Nessun dato ancora",
        no_more: "No more",
        number: "Quantità",
        other: "Altri",
        password_not_equal: "Inconsigenza della password",
        product_list: "Elenco prodotti",
        quotation: "Variazione 24H",
        set: "Imposta",
        setting: "Impostazione",
        status: "Status",
        submit: "Invia",
        submit_order: "Invia ordine",
        tip: "Prompt",
        upload_pic: "Caricamento certificato",
        use: "Disponibile"
    },
    trade: {
        balance: "Saldo",
        best_price: "Trading al miglior prezzo corrente",
        buy: "Compra",
        buy_low: "Shorting",
        buy_price: "Prezzo",
        buy_up: "andare lungo",
        close_order: "Posizione di chiusura",
        closed: "Chiuso",
        current_entrust: "Messa in servizio preliminare",
        enter_price: "Inserisci il prezzo",
        high: "Alto",
        last_price: "Prezzo di mercato",
        low: "Basso",
        no_mult: "Nessun multiplo",
        no_mult_config: "Nessuna leva configurata, impossibile effettuare un ordine temporaneamente",
        open: "Apri",
        operate: "Operazione",
        order: "Ordini",
        order_confirm: "Il tuo ordine è stato confermato",
        order_page: "Ordine pagina",
        price: "prezzo",
        sell: "Vendi",
        time: "Data",
        trade_amount: "Dimensione del lotto di negoziazione",
        trade_price: "Importo dell'operazione",
        type: "Tipo",
        volume: "Volume totale"
    },
    user: {
        account_detail: "Dettagli del conto",
        announ: "annuncio",
        announ_text: "Per motivi politici, i servizi non vengono forniti a Corea del Nord, Israele, Cina, Vanuatu e Cuba.",
        area_code: "Codice della zona",
        can_use_asset: "Attività disponibili",
        com_email: "Email di reclamo",
        confirm_logout: "Se recedere dal registro",
        confirm_reset: "Indica se restituirlo",
        copy_invite_link: "Copia link di invito",
        dh: "telefono",
        email: "Casella postale",
        forgot_password: "Password dimenticata",
        h_accept: "Accetta",
        h_second: "secondo",
        h_timer: "conto alla rovescia",
        h_welc: "Benvenuto in uso",
        input_email: "Inserisci il tuo indirizzo email",
        input_invite_code: "Inserisci il codice dell'invito",
        input_old_password: "Inserisci la tua vecchia password",
        input_password: "Inserisci la tua password",
        input_phone: "Inserisci il tuo numero di cellulare",
        invite_code: "Codice invito",
        join_us: "Unisciti ora",
        login: "Login",
        login_email: "Inserisci la tua email",
        login_phone: "Accesso mobile",
        logout: "Esci",
        logout_success: "Uscita riuscita",
        mock_account: "Conto di simulazione",
        mock_login: "Non c'è bisogno di aprire un conto, crea un conto demo per fare trading ora!",
        new_paw: "Nuova password",
        new_user: "Nuovo utente?",
        only_true_account: "Si prega di registrarsi o accedere a un account vero e proprio hard",
        password: "Password",
        please_login: "Effettua il login",
        qh: "prefisso",
        qh_dh: "prefisso/telefono",
        re_input_pass: "Digita nuovamente la password",
        register: "Registrati",
        register_email: "Inserisci la tua email",
        register_phone: "Registrazione mobile",
        reset_mock_account: "Restituisce conto simulato",
        trade_account: "Conto di trading",
        useful_balance: "Fondi disponibili",
        user_center: "Il mio",
        welcome_login: "Benvenuto all'accesso",
        welcome_register: "Benvenuto per registrarsi"
    },
    wallet: {
        bind: "vincolante",
        bind_bank_card: "Carta bancaria vincolata",
        bind_coin_address: "Indirizzo in valuta digitale vincolante",
        not_bind: "Si prega di impostare un account di raccolta prima",
        save: "conservazione"
    },
    withdraw: {
        amount: "Importo",
        calc_get: "Si prevede che",
        no_pay_way: "Non esiste un canale di pagamento per il conto in valuta francese.",
        record: "Record di prelievo di monete",
        select_withdraw_address: "Seleziona un indirizzo da chiamare",
        send_amount: "Numero di ricevute",
        symbol: "Valuta",
        unit: "Unità",
        withdraw_address: "Indirizzo per i prelievi di monete",
        withdraw_coin: "Invia il recesso"
    }
}
obj.tr = {
    auth: {
        back: "Ters taraf",
        finish: "Kullanılmış",
        front: "Ön",
        id_number: "Pasaportu/Kimlik Numarası",
        input_id_num: "Lütfen pasaportunuzu/kimlik numarasını girin",
        input_info: "Lütfen kişisel bilgilerinizi tamamlayın",
        input_name: "Lütfen isminizi girin",
        name: "İsim",
        upload_back: "Pasaportunuz/sertifikanızın tersi tarafını yükleyin",
        upload_front: "Pasaportunuz/sertifikanızın önünü yükleyin",
        upload_idcard: "Lütfen pasaportunuz/kimliğinizin önünü ve arkasını yükleyin",
        waiting: "Seçme",
        reject: "reddetme",
        auth_again: "Yeniden sertifika"
    },
    bank: {
        SWIFT: "SWIFT",
        account: "Payee Hesabı",
        address: "Banka Adresi",
        bank_name: "Şube adı",
        coin_type: "Fazi Vali",
        name: "Banka İsmi",
        remark: "RemarkComment",
        swift_text: "SWIFT",
        username: "Payee"
    },
    financial: {
        bank: "Banka Kartı",
        bank_charge: "telegraf kablo",
        charge_amount: "Para sayısı",
        charge_coin: "Coins",
        charge_history: "Kayıt",
        charge_num: "Tekrar yükle miktarı",
        coin: "Dijital Valüt",
        collect_coin_type: "Parayı alın",
        copy_address: "Adresi kopyala",
        financial_records: "Mali Kayıtlar",
        input_charge_amount: "Lütfen yüklenecek paranın sayısını girin",
        input_charge_num: "Lütfen yeniden yükleme miktarını girin",
        isfinish: "Tamamlandı",
        isreject: "Red edildi",
        link_charge: "Zincirle yeniden yükleyin.",
        select_network: "Ağ Seç",
        uncheck: "Kontrol edilmemiş",
        unuseful: "Keçersiz",
        wallet_address: "Cüzdanın Adresi"
    },
    forgot: {
        input_sms_code: "Lütfen doğrulama kodunu girin",
        reset: "Parolayı Sıfırla",
        send: "Gönderin",
        sms_code: "Kontrol Kodu"
    },
    func: {
        billing_detail: "Faturalama Ayrıntıları",
        charge_coin: "Depozit",
        invite_friend: "Arkadaşları davet et",
        lang: "Dil",
        real_auth: "Gerçek İsim Denetimi",
        reset_password: "Parola değiştir",
        setting: "kurum",
        wallet: "CüzdanComment",
        withdraw_coin: "Çıkarma"
    },
    order: {
        all_caution_money: "Şimdiki Margin",
        balance: "Balans",
        bond_fee: "Margin",
        buy_in: "Alın.",
        calc_bond_fee: "Tahmin edilen Margin",
        calc_handle_fee: "Tahmin edilen Yönetim Beslemesi",
        can_use: "Ön ödeme mevcut",
        cancel: "Düzeni iptal et",
        close: "Yerini kapat",
        close_price: "Peynir depo fiyatı",
        confrim_close_order: "Konum kapatılması",
        currency: "Transaksyon çiftleri",
        current_price: "Şimdiki fiyat",
        dealing: "Bekleyen Emirler",
        detail: "Sıra Ayrıntıları",
        hand_num: "Çok fazla",
        handle_fee: "Ödeme yönetimi",
        hazard_rate: "Risk Rate",
        history: "Tarihi",
        holding: "Konum tutuyor",
        income: "kazanç",
        market_price: "Pazar Ödülü",
        open_time: "Aç vakti",
        order_id: "Koruma numarası",
        order_price: "Aşağıdaki birim fiyatı",
        order_type: "yön",
        page: "Tablo",
        pay_rate: "Ön ödeme Derisi",
        profit: "İşler ve Kaybetler",
        sell_out: "Satın",
        set_stop_price: "Kaydet",
        stop_loss: "Kaybı ayarla",
        stop_profit: "Profit ayarla",
        times: "Çoklucu",
        total: "Ağ değeri",
        total_ol: "tamamen kazanç ve kaybı",
        trade_amount: "Çok fazla",
        trading: "Konumlar",
        un_deal_order: "Bekleyen Emirler",
        unit: "Her çarşaf"
    },
    public: {
        tocust: "Şimdi temas edin",
        add: "Ekle",
        cancel: "Lütfen",
        chat: "Çevrimiçi Hizmeti",
        coming_soon: "Yakında geliyorum",
        confirm: "onaylama",
        confirm_del: "Silecek misin?",
        copy: "Kopiyalar",
        copy_success: "Başarılı kopyalandı",
        delete: "Sil",
        freeze: "Dock this window",
        history: "Kaydet",
        input_full: "Lütfen tamamlayın.",
        last_price: "Son Ödül",
        market: "Trend",
        name: "İsim",
        no_data: "Henüz veri yok",
        no_more: "Daha fazla yok.",
        number: "Kıymet",
        other: "Başka",
        password_not_equal: "Parola uyumsuz",
        product_list: "Produkt Listesi",
        quotation: "24H Değiştirme",
        set: "Ayarla",
        setting: "Ayarlama",
        status: "Durum",
        submit: "Teslim et",
        submit_order: "Sırasını yolla",
        tip: "Sor",
        upload_pic: "Sertifikat Yükleniyor",
        use: "Mevcut"
    },
    trade: {
        balance: "Balans",
        best_price: "En iyi fiyat satışı",
        buy: "Alın.",
        buy_low: "Kısayol",
        buy_price: "Ödül",
        buy_up: "Uzun git.",
        close_order: "Yerini kapat",
        closed: "Gizli",
        current_entrust: "Önceki Komisyon",
        enter_price: "Lütfen fiyatı girin",
        high: "Yüksek",
        last_price: "Pazar Ödülü",
        low: "Düşük",
        no_mult: "Çoklu yok",
        no_mult_config: "Hiçbir lever ayarlanmadı, sırayı geçici olarak yerleştiremedi",
        open: "Aç",
        operate: "Operasyon",
        order: "Emirler",
        order_confirm: "Emriniz onaylandı.",
        order_page: "Sıra Sayfası",
        price: "fiyat",
        sell: "Satın",
        time: "Tarih",
        trade_amount: "Trading lot size",
        trade_price: "Transaksyon miktarı",
        type: "Türü",
        volume: "Toplam ses"
    },
    user: {
        account_detail: "Hesap Ayrıntıları",
        announ: "duyuru",
        announ_text: "Politik sebepleri yüzünden Kuzey Kore, İsrail, Çin, Vanuatu ve Kuba'ya hizmet verilmez.",
        area_code: "Bölge Kodu",
        can_use_asset: "Available Assets",
        com_email: "Sıkıştırma e- postası",
        confirm_logout: "Kayıtlardan çekilmeyecek mi?",
        confirm_reset: "Döndürmek mi?",
        copy_invite_link: "Davet Bağlantısını Kopiyala",
        dh: "telefon",
        email: "E-posta",
        forgot_password: "Parola unut",
        h_accept: "Kabul et",
        h_second: "saniye",
        h_timer: "sayım",
        h_welc: "Kullanmaya hoş geldiniz.",
        input_email: "Lütfen e-posta adresinizi girin",
        input_invite_code: "Lütfen davet kodunu girin",
        input_old_password: "Lütfen eski parolanızı girin",
        input_password: "Lütfen parolanızı girin",
        input_phone: "Lütfen cep telefonunuzu girin.",
        invite_code: "Davet Kodu",
        join_us: "Şimdi katılın",
        login: "İçeri gir",
        login_email: "E-postanızı girin",
        login_phone: "Mobil Giriş",
        logout: "Çıkış",
        logout_success: "Başarılı çıkış",
        mock_account: "Simülasyon Hesabı",
        mock_login: "Bir hesap a çma gerek yok, şimdi ticaret için demo hesabı oluştur!",
        new_paw: "Yeni parola",
        new_user: "Yeni Kullanıcı?",
        only_true_account: "Lütfen gerçek zor bir hesaba kayıt veya girin",
        password: "Parola",
        please_login: "Lütfen girin",
        qh: "bölge kodu",
        qh_dh: "bölge kodu/telefonu",
        re_input_pass: "Parola yeniden yaz",
        register: "Kayıt",
        register_email: "E-postanızı girin",
        register_phone: "Mobil Kayıt",
        reset_mock_account: "Simüle hesabı geri verin",
        trade_account: "Satış hesabı",
        useful_balance: "Mevcut fonlar",
        user_center: "Benim.",
        welcome_login: "İçeri girmenize hoş geldiniz",
        welcome_register: "Kayıtlara hoş geldiniz"
    },
    wallet: {
        bind: "bağ",
        bind_bank_card: "Bağlam banka kartı",
        bind_coin_address: "Dijital para adresini bağla",
        not_bind: "Lütfen önce bir koleksiyon hesabını ayarlayın",
        save: "koruması"
    },
    withdraw: {
        amount: "Kıymet",
        calc_get: "Görünüşe göre",
        no_pay_way: "Fransız para hesabı için ödeme kanalı yok.",
        record: "Coin çekilme kayıtları",
        select_withdraw_address: "Lütfen aramak için bir adres seçin",
        send_amount: "Gönderilen Sayısı",
        symbol: "Para",
        unit: "Birim",
        withdraw_address: "Para çekilmesi adresi",
        withdraw_coin: "Çıkarmayı yolla"
    }
}
obj.id = {
    auth: {
        back: "sisi sebaliknya",
        finish: "diverifikasi",
        front: "depan",
        id_number: "Nomor paspor/ID",
        input_id_num: "Silakan masukkan nomor paspor/ID Anda",
        input_info: "Silakan lengkapi pesannya secara pribadi",
        input_name: "Silahkan masukan nama anda",
        name: "Nama",
        upload_back: "Unggah sisi belakang paspor/KTP Anda",
        upload_front: "Unggah bagian depan paspor/KTP Anda",
        upload_idcard: "Silakan unggah bagian depan dan belakang paspor/sertifikat Anda",
        waiting: "sedang ditinjau",
        reject: "menolak",
        auth_again: "Sertifikasi ulang"
    },
    bank: {
        SWIFT: "cepat",
        account: "Akun penerima pembayaran",
        address: "Alamat bank",
        bank_name: "Nama cabang",
        coin_type: "Mata uang penerimaan",
        name: "nama Bank",
        remark: "Komentar",
        swift_text: "cepat",
        username: "Penerima pembayaran"
    },
    financial: {
        bank: "kartu bank",
        bank_charge: "transfer rekening",
        charge_amount: "Kuantitas isi ulang",
        charge_coin: "isi ulang",
        charge_history: "Catatan setoran",
        charge_num: "Jumlah isi ulang",
        coin: "mata uang digital",
        collect_coin_type: "Mata uang penerimaan",
        copy_address: "Salin alamat",
        financial_records: "catatan keuangan",
        input_charge_amount: "Silakan masukkan jumlah isi ulang",
        input_charge_num: "Silakan masukkan jumlah isi ulang",
        isfinish: "lengkap",
        isreject: "ditolak",
        link_charge: "Isi ulang pada rantai",
        select_network: "Pilih jaringan",
        uncheck: "Tidak ditinjau",
        unuseful: "tidak sah",
        wallet_address: "alamat dompet"
    },
    forgot: {
        input_sms_code: "silakan masukkan kode verifikasi",
        reset: "mengatur ulang kata sandi",
        send: "mengantarkan",
        sms_code: "Kode verifikasi"
    },
    func: {
        billing_detail: "Detail penagihan",
        charge_coin: "Menyetorkan",
        invite_friend: "mengundang teman-teman",
        lang: "bahasa",
        real_auth: "Diverifikasi",
        reset_password: "ganti kata sandi",
        setting: "mempersiapkan",
        wallet: "dompet",
        withdraw_coin: "Tarik dana"
    },
    order: {
        all_caution_money: "Margin saat ini",
        balance: "Keseimbangan",
        bond_fee: "batas",
        buy_in: "Membeli",
        calc_bond_fee: "Margin devisa",
        calc_handle_fee: "Biaya tambahan",
        can_use: "Pembayaran di muka tersedia",
        cancel: "Batalkan pesanan",
        close: "Tutup posisi",
        close_price: "Harga penutup",
        confrim_close_order: "Apakah akan menutup posisi",
        currency: "pasangan perdagangan",
        current_price: "harga sekarang",
        dealing: "pesanan tertunda",
        detail: "Detail pesanan",
        hand_num: "Banyak ukuran",
        handle_fee: "Biaya penanganan",
        hazard_rate: "tingkat risiko",
        history: "sejarah",
        holding: "Posisi",
        income: "penghasilan",
        market_price: "harga pasar",
        open_time: "Waktu pembukaan",
        order_id: "jumlah order",
        order_price: "Harga pesanan",
        order_type: "arah",
        page: "membuka",
        pay_rate: "Rasio pembayaran di muka",
        profit: "Laba rugi",
        sell_out: "menjual",
        set_stop_price: "Ubah take profit dan stop loss",
        stop_loss: "menghentikan kerugian",
        stop_profit: "Mengambil keuntungan",
        times: "banyak",
        total: "kekayaan bersih",
        total_ol: "Jumlah untung dan rugi",
        trade_amount: "Beli kuantitas",
        trading: "Buka posisi",
        un_deal_order: "pesanan tertunda",
        unit: "setiap"
    },
    public: {
        tocust: "Kontak Sekarang",
        add: "Tambahkan",
        cancel: "Membatalkan",
        chat: "Hubungi Layanan Pelanggan",
        coming_soon: "Menantikan~",
        confirm: "mengonfirmasi",
        confirm_del: "konfirmasi untuk menghapus?",
        copy: "menyalin",
        copy_success: "Berhasil disalin",
        delete: "menghapus",
        freeze: "duduk",
        history: "Catatan",
        input_full: "Silakan isi secara lengkap",
        last_price: "Harga terbaru",
        market: "kecenderungan",
        name: "nama",
        no_data: "Tidak ada data",
        no_more: "tidak lagi",
        number: "kuantitas",
        other: "lainnya",
        password_not_equal: "Kebingungan kata sandi",
        product_list: "Daftar Produk",
        quotation: "Perubahan kutipan",
        set: "Merevisi",
        setting: "mempersiapkan",
        status: "negara",
        submit: "kirim",
        submit_order: "Memesan",
        tip: "petunjuk",
        upload_pic: "Unggah sumber daya",
        use: "Tersedia"
    },
    trade: {
        balance: "Keseimbangan",
        best_price: "Bertransaksi dengan harga terbaik saat ini",
        buy: "Membeli",
        buy_low: "pendek",
        buy_price: "harga",
        buy_up: "Duoduo",
        close_order: "Tutup posisi",
        closed: "Pasar ditutup",
        current_entrust: "Pesanan saat ini",
        enter_price: "Silakan masukkan harga",
        high: "tinggi",
        last_price: "harga pasar",
        low: "Rendah",
        no_mult: "Belum ada kelipatannya",
        no_mult_config: "Leverage tidak dikonfigurasi dan pesanan tidak dapat dilakukan untuk sementara.",
        open: "membuka",
        operate: "beroperasi",
        order: "Memesan",
        order_confirm: "Pesanan Anda telah dikonfirmasi",
        order_page: "Halaman pesanan",
        price: "harga satuan",
        sell: "menjual",
        time: "waktu",
        trade_amount: "Banyak perdagangan",
        trade_price: "Volume transaksi",
        type: "jenis",
        volume: "ringkasan"
    },
    user: {
        account_detail: "Detail akun",
        announ: "pengumuman",
        announ_text: "Karena alasan kebijakan, layanan tidak disediakan ke Korea Utara, Israel, Tiongkok, Vanuatu, dan Kuba.",
        area_code: "daerah",
        can_use_asset: "Aset yang tersedia",
        com_email: "Email pengaduan",
        confirm_logout: "Apakah akan logout",
        confirm_reset: "Setel ulang atau tidak",
        copy_invite_link: "Salin tautan undangan",
        dh: "Telepon",
        email: "Surat",
        forgot_password: "lupa kata sandinya",
        h_accept: "menerima",
        h_second: "Kedua",
        h_timer: "Hitung mundur",
        h_welc: "selamat datang",
        input_email: "Masukkan email Anda",
        input_invite_code: "Silakan masukkan kode undangan",
        input_old_password: "Silakan masukkan kata sandi lama",
        input_password: "Silakan masukkan kata sandi",
        input_phone: "Masukkan nomor telepon anda",
        invite_code: "Kode undangan",
        join_us: "Bergabung sekarang",
        login: "Gabung",
        login_email: "Masuk Email",
        login_phone: "Masuk dengan telepon",
        logout: "keluar",
        logout_success: "keluar dengan sukses",
        mock_account: "Akun demo",
        mock_login: "Tidak perlu membuka akun, buat akun demo dan mulai trading sekarang!",
        new_paw: "kata sandi baru",
        new_user: "pengguna baru?",
        only_true_account: "Silakan mendaftar atau masuk ke akun nyata",
        password: "kata sandi",
        please_login: "silakan masuk",
        qh: "daerah",
        qh_dh: "Kode area/telepon",
        re_input_pass: "Masukkan kata sandi lagi",
        register: "daftar",
        register_email: "registrasi email",
        register_phone: "Daftarkan telepon Anda",
        reset_mock_account: "Setel ulang akun demo",
        trade_account: "akun transaksi",
        useful_balance: "Dana yang tersedia",
        user_center: "Pusat Pribadi",
        welcome_login: "Silakan masuk",
        welcome_register: "Selamat mendaftar"
    },
    wallet: {
        bind: "mengikat",
        bind_bank_card: "Ikat kartu bank",
        bind_coin_address: "Ikat alamat mata uang digital",
        not_bind: "Harap siapkan akun pembayaran terlebih dahulu",
        save: "menyimpan"
    },
    withdraw: {
        amount: "kuantitas",
        calc_get: "Perkiraan kuantitas kedatangan",
        no_pay_way: "Saat ini tidak ada saluran pembayaran untuk rekening mata uang resmi ini",
        record: "Catatan penarikan koin",
        select_withdraw_address: "Silakan pilih alamat mata uang",
        send_amount: "Kuantitas kedatangan",
        symbol: "mata uang",
        unit: "satuan",
        withdraw_address: "Alamat penarikan",
        withdraw_coin: "Tarik koin"
    }
}
obj.my = {
    auth: {
        back: "ပြောင်းပြန်ဘက်",
        finish: "စိစစ်ပြီး",
        front: "ရှေ့",
        id_number: "နိုင်ငံကူးလက်မှတ်/ ID နံပါတ်",
        input_id_num: "ကျေးဇူးပြု၍ သင်၏ နိုင်ငံကူးလက်မှတ်/ ID နံပါတ်ကို ထည့်ပါ။",
        input_info: "ကျေးဇူးပြု၍ စာကို ကိုယ်တိုင်ကိုယ်ကျ ဖြည့်သွင်းပါ။",
        input_name: "ကျေးဇူးပြု၍ သင့်အမည်ကို ထည့်ပါ။",
        name: "နာမည်",
        upload_back: "သင့်ပတ်စပို့/ ID ၏ နောက်ဘက်ခြမ်းကို အပ်လုဒ်လုပ်ပါ။",
        upload_front: "သင့်ပတ်စပို့/ ID ၏ ရှေ့တွင် အပ်လုဒ်လုပ်ပါ။",
        upload_idcard: "ကျေးဇူးပြု၍ သင့်ပတ်စ်ပို့/လက်မှတ်၏ ရှေ့နှင့်နောက်ကို အပ်လုဒ်လုပ်ပါ။",
        waiting: "သုံးသပ်ချက်အောက်တွင်",
        reject: "ငြင်းပယ်ပါ",
        auth_again: "အသိအမှတ်ပြုချက်ကို ပြန်ပြန်ပါ"
    },
    bank: {
        SWIFT: "မြန်သည်။",
        account: "ငွေလက်ခံသူအကောင့်",
        address: "ဘဏ်လိပ်စာ",
        bank_name: "ဘဏ်ခွဲအမည်",
        coin_type: "ငွေလက်ခံဖြတ်ပိုင်း",
        name: "ဘဏ်အမည်",
        remark: "မှတ်ချက်",
        swift_text: "မြန်သည်။",
        username: "ငွေလက်ခံသူ"
    },
    financial: {
        bank: "ဘဏ်ကဒ်",
        bank_charge: "ငွေလွှဲခြင်း",
        charge_amount: "ပမာဏကို ပြန်ဖြည့်ပါ။",
        charge_coin: "ငွေဖြည့်",
        charge_history: "အပ်ငွေမှတ်တမ်း",
        charge_num: "အားပြန်သွင်းသည့်ပမာဏ",
        coin: "ဒစ်ဂျစ်တယ်ငွေကြေး",
        collect_coin_type: "ငွေလက်ခံဖြတ်ပိုင်း",
        copy_address: "လိပ်စာကို ကူးယူပါ။",
        financial_records: "ဘဏ္ဍာရေးမှတ်တမ်းများ",
        input_charge_amount: "ကျေးဇူးပြု၍ အားပြန်သွင်းသည့်ပမာဏကို ထည့်ပါ။",
        input_charge_num: "ကျေးဇူးပြု၍ အားပြန်သွင်းသည့်ပမာဏကို ထည့်ပါ။",
        isfinish: "ပြီးပါပြီ။",
        isreject: "ငြင်းပယ်ခဲ့သည်။",
        link_charge: "ကြိုးပေါ်တွင် အားပြန်သွင်းပါ။",
        select_network: "ကွန်ရက်ကို ရွေးပါ။",
        uncheck: "မသုံးသပ်ပါ။",
        unuseful: "မမှန်ပါ။",
        wallet_address: "ပိုက်ဆံအိတ်လိပ်စာ"
    },
    forgot: {
        input_sms_code: "ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ထည့်ပါ။",
        reset: "လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်",
        send: "ပို့ဆောင်",
        sms_code: "အတည်ပြုရန်ကုတ်"
    },
    func: {
        billing_detail: "ငွေတောင်းခံမှုအသေးစိတ်",
        charge_coin: "အပ်ငွေ",
        invite_friend: "သူငယ်ချင်းများကိုဖိတ်ကြားသည်",
        lang: "ဘာသာစကား",
        real_auth: "စိစစ်ပြီး",
        reset_password: "စကားဝှက်ကိုပြောင်းရန်",
        setting: "တည်ဆောက်သည်",
        wallet: "ပိုက်ဆံအိတ်",
        withdraw_coin: "ရန်ပုံငွေများထုတ်ယူ"
    },
    order: {
        all_caution_money: "လက်ရှိအနားသတ်",
        balance: "လက်ကျန်",
        bond_fee: "အနားသတ်",
        buy_in: "ဝယ်",
        calc_bond_fee: "နိုင်ငံခြားငွေလဲလှယ်နှုန်း",
        calc_handle_fee: "အပိုကြေး",
        can_use: "ကြိုတင်ငွေပေးချေနိုင်ပါသည်။",
        cancel: "အော်ဒါဖျက်သိမ်းသည်",
        close: "အနီးကပ်အနေအထား",
        close_price: "အပိတ်ဈေး",
        confrim_close_order: "ရာထူးပိတ်မှာလား။",
        currency: "ကုန်သွယ်မှုစုံတွဲ",
        current_price: "လက်ရှိစျေးနှုန်း",
        dealing: "ဆိုင်းငံ့ထားသောအမိန့်",
        detail: "အော်ဒါအသေးစိတ်",
        hand_num: "အများကြီးအရွယ်အစား",
        handle_fee: "ကိုင်တွယ်ခ",
        hazard_rate: "အန္တရာယ်နှုန်း",
        history: "သမိုင်း",
        holding: "ရာထူး",
        income: "ဝင်ငွေ",
        market_price: "ဈေးကွက်ပေါက်ဈေး",
        open_time: "ဖွင့်ချိန်",
        order_id: "အော်ဒါနံပါတ်",
        order_price: "အော်ဒါစျေးနှုန်း",
        order_type: "ဦးတည်ချက်",
        page: "ဖွင့်သည်။",
        pay_rate: "ကြိုတင်ငွေပေးချေမှုအချိုး",
        profit: "အရှုံးအမြတ်",
        sell_out: "ရောင်း",
        set_stop_price: "အမြတ်ယူ၍ အရှုံးကို ရပ်တန့်ကာ ပြင်ဆင်ပါ။",
        stop_loss: "ဆုံးရှုံးမှုရပ်တန့်",
        stop_profit: "အမြတ်ယူပါ။",
        times: "မျိုးစုံ",
        total: "အသားတင်တန်တယ်။",
        total_ol: "စုစုပေါင်းအရှုံးအမြတ်",
        trade_amount: "ပမာဏကိုဝယ်ပါ။",
        trading: "ရာထူးတစ်ခုဖွင့်ပါ။",
        un_deal_order: "ဆိုင်းငံ့ထားသောအမိန့်",
        unit: "တစ်ခုစီ"
    },
    public: {
        tocust: "အခု ဆက်သွယ်ပါ",
        add: "ထည့်သည်",
        cancel: "မလုပ်တော့",
        chat: "ဖောက်သည်ဝန်ဆောင်မှုကို ဆက်သွယ်ပါ။",
        coming_soon: "စောင့်မျှော်နေပါတယ်~",
        confirm: "အတည်ပြုပါ။",
        confirm_del: "ဖျက်ရန် အတည်ပြုမလား။",
        copy: "ကော်ပီ",
        copy_success: "အောင်မြင်စွာ ကူးယူထားပါသည်။",
        delete: "ဖျက်ပါ။",
        freeze: "ထိုင်",
        history: "မှတ်တမ်း",
        input_full: "အပြည့်အစုံဖြည့်ပါ။",
        last_price: "နောက်ဆုံးစျေးနှုန်း",
        market: "လမ်းကြောင်းသစ်",
        name: "နာမည်",
        no_data: "ဒေတာမရှိပါ။",
        no_more: "မရှိတော့ပါ",
        number: "ပမာဏ",
        other: "အခြား",
        password_not_equal: "စကားဝှက်ရှုပ်ထွေးခြင်း။",
        product_list: "ကုန်ပစ္စည်းစာရင်း",
        quotation: "ကိုးကားပြောင်းလဲခြင်း။",
        set: "ပြန်လည်ပြင်ဆင်ပါ။",
        setting: "တည်ဆောက်သည်",
        status: "ပြည်နယ်",
        submit: "တင်ပြပါ။",
        submit_order: "အော်ဒါတင်ပါ။",
        tip: "သဲလွန်စ",
        upload_pic: "အရင်းအမြစ်များကို တင်ပါ။",
        use: "ရရှိနိုင်ပါသည်။"
    },
    trade: {
        balance: "လက်ကျန်",
        best_price: "လက်ရှိပေါက်ဈေးဖြင့် အရောင်းအ၀ယ်လုပ်ပါ။",
        buy: "ဝယ်",
        buy_low: "တိုတို",
        buy_price: "စျေးနှုန်း",
        buy_up: "ရှည်သွားလိုက်",
        close_order: "အနီးကပ်အနေအထား",
        closed: "ဈေးပိတ်တယ်။",
        current_entrust: "လက်ရှိအော်ဒါ",
        enter_price: "ကျေးဇူးပြု၍ စျေးနှုန်းထည့်ပါ။",
        high: "မြင့်မားသော",
        last_price: "ဈေးကွက်ပေါက်ဈေး",
        low: "နိမ့်သည်။",
        no_mult: "အဆမတန်သေးပါ။",
        no_mult_config: "Leverage ကို မသတ်မှတ်ထားဘဲ မှာကြားမှုများကို ယာယီထား၍မရပါ။",
        open: "ဖွင့်သည်။",
        operate: "လည်ပတ်",
        order: "အော်",
        order_confirm: "သင့်မှာယူမှုကို အတည်ပြုပြီးပါပြီ။",
        order_page: "အော်ဒါစာမျက်နှာ",
        price: "တစ်ခုချင်းစျေးနှုန်း",
        sell: "ရောင်း",
        time: "အချိန်",
        trade_amount: "အရောင်းအဝယ်တွေအများကြီး",
        trade_price: "ငွေပေးငွေယူပမာဏ",
        type: "အမျိုးအစား",
        volume: "အနှစ်ချုပ်"
    },
    user: {
        account_detail: "အကောင့်အသေးစိတ်",
        announ: "ကြေငြာချက်",
        announ_text: "မူဝါဒအကြောင်းများကြောင့်၊ မြောက်ကိုရီးယား၊ အစ္စရေး၊ တရုတ်၊ ဗာနူအာတူနှင့် ကျူးဘားတို့အား ဝန်ဆောင်မှုများ မပေးဆောင်ပါ။",
        area_code: "ဧရိယာ",
        can_use_asset: "ရနိုင်သောပစ္စည်းများ",
        com_email: "တိုင်ကြားစာ အီးမေးလ်",
        confirm_logout: "ထွက်မလား။",
        confirm_reset: "ပြန်လည်သတ်မှတ်သည်ဖြစ်စေ မပြုလုပ်ပါ။",
        copy_invite_link: "ဖိတ်ကြားချက်လင့်ခ်ကို ကူးယူပါ။",
        dh: "တယ်လီဖုန်း",
        email: "မေးလ်",
        forgot_password: "စကားဝှက်ကိုမေ့",
        h_accept: "လက်ခံ",
        h_second: "ဒုတိယ",
        h_timer: "နှစ်သစ်",
        h_welc: "ကြိုဆိုပါတယ်။",
        input_email: "သင့်အီးမေးလ်ကို ထည့်ပါ။",
        input_invite_code: "ကျေးဇူးပြု၍ ဖိတ်ကြားချက်ကုဒ်ကို ထည့်ပါ။",
        input_old_password: "စကားဝှက်ဟောင်းကို ထည့်ပါ။",
        input_password: "စကားဝှက်ထည့်ပါ။",
        input_phone: "ကျေးဇူးပြု၍ သင့်ဖုန်းနံပါတ်ကို ထည့်ပါ။",
        invite_code: "ဖိတ်စာကုဒ်",
        join_us: "ယခုချိတ်ဆက်ပါ။",
        login: "လော့ဂ်အင်",
        login_email: "အီးမေးလ် အကောင့်ဝင်ပါ။",
        login_phone: "ဖုန်းဖြင့်ဝင်ရောက်ပါ။",
        logout: "ဆိုင်းအောက်",
        logout_success: "အောင်မြင်စွာ ထွက်လိုက်ပါ။",
        mock_account: "ဒီမိုအကောင့်",
        mock_login: "အကောင့်ဖွင့်ရန်မလိုအပ်ပါ၊ သရုပ်ပြအကောင့်တစ်ခုဖန်တီးပြီး အရောင်းအဝယ်စတင်ပါ။",
        new_paw: "စကားဝှက်အသစ်",
        new_user: "အသုံးပြုသူအသစ်လား။",
        only_true_account: "ကျေးဇူးပြု၍ စာရင်းသွင်းပါ သို့မဟုတ် အကောင့်အစစ်သို့ ဝင်ရောက်ပါ။",
        password: "စကားဝှက်",
        please_login: "ကျေးဇူးပြု၍ ဝင်ရောက်ပါ။",
        qh: "ဧရိယာ",
        qh_dh: "ဧရိယာကုဒ်/ဖုန်း",
        re_input_pass: "စကားဝှက်ကို ထပ်ထည့်ပါ။",
        register: "မှတ်ပုံတင်",
        register_email: "အီးမေးလ်မှတ်ပုံတင်ခြင်း။",
        register_phone: "သင့်ဖုန်းကို မှတ်ပုံတင်ပါ။",
        reset_mock_account: "သရုပ်ပြအကောင့်ကို ပြန်လည်သတ်မှတ်ပါ။",
        trade_account: "ငွေပေးငွေယူအကောင့်",
        useful_balance: "ရနိုင်သောရန်ပုံငွေများ",
        user_center: "ကိုယ်ပိုင်စင်တာ",
        welcome_login: "ကျေးဇူးပြု၍ ဝင်ရောက်ပါ။",
        welcome_register: "စာရင်းသွင်းရန်ကြိုဆိုပါသည်။"
    },
    wallet: {
        bind: "ချည်နှောင်ခြင်း။",
        bind_bank_card: "ဘဏ်ကတ်ကို ချိတ်ပါ။",
        bind_coin_address: "ဒစ်ဂျစ်တယ်ငွေကြေးလိပ်စာကို စည်းပါ။",
        not_bind: "ကျေးဇူးပြု၍ ငွေပေးချေမှုအကောင့်ကို ဦးစွာသတ်မှတ်ပါ။",
        save: "ကယ်တင်ပါ။"
    },
    withdraw: {
        amount: "ပမာဏ",
        calc_get: "ခန့်မှန်းခြေ ရောက်ရှိလာသည့် ပမာဏ",
        no_pay_way: "လောလောဆယ်တွင် ဤတရားဝင်ငွေကြေးအကောင့်အတွက် ငွေပေးချေမှုလမ်းကြောင်းမရှိပါ။",
        record: "အကြွေစေ့ထုတ်ယူမှုမှတ်တမ်း",
        select_withdraw_address: "ငွေကြေးလိပ်စာကို ရွေးပါ။",
        send_amount: "ဆိုက်ရောက်အရေအတွက်",
        symbol: "ငွေကြေး",
        unit: "ယူနစ်",
        withdraw_address: "ငွေထုတ်လိပ်စာ",
        withdraw_coin: "ငွေဒင်္ဂါးများကိုထုတ်ယူပါ။"
    }
}
obj.hi = {
    auth: {
        back: "विपरीत पक्ष",
        finish: "प्रमाणित",
        front: "सामने",
        id_number: "पासपोर्ट/आईडी नंबर",
        input_id_num: "कृपया अपना पासपोर्ट/आईडी नंबर दर्ज करें",
        input_info: "कृपया अपनी व्यक्तिगत जानकारी पूरी करें",
        input_name: "कृपया अपना नाम दर्ज करें",
        name: "नाम",
        upload_back: "अपने पासपोर्ट/प्रमाणपत्र का पिछला भाग अपलोड करें",
        upload_front: "अपने पासपोर्ट/प्रमाणपत्र का अगला भाग अपलोड करें",
        upload_idcard: "कृपया अपने पासपोर्ट/आईडी का अगला और पिछला भाग अपलोड करें",
        waiting: "अंकेक्षण",
        reject: "अस्वीकार करें",
        auth_again: "प्रमाणपत्र"
    },
    bank: {
        SWIFT: "तीव्र",
        account: "आदाता खाता",
        address: "बैंक पता",
        bank_name: "शाखा का नाम",
        coin_type: "लाभार्थी मुद्रा",
        name: "बैंक का नाम",
        remark: "टिप्पणी",
        swift_text: "तीव्र",
        username: "आदाता"
    },
    financial: {
        bank: "बैंक कार्ड",
        bank_charge: "टेलीग्राफ तार",
        charge_amount: "सिक्कों की संख्या",
        charge_coin: "सिक्के",
        charge_history: "जमा रिकार्ड",
        charge_num: "पुनर्भरण राशि",
        coin: "डिजिटल मुद्रा",
        collect_coin_type: "रसीद मुद्रा",
        copy_address: "पता कॉपी करें",
        financial_records: "वित्तीय रिकॉर्ड",
        input_charge_amount: "कृपया चार्ज किए जाने वाले सिक्कों की संख्या दर्ज करें",
        input_charge_num: "कृपया रिचार्ज राशि दर्ज करें",
        isfinish: "पुरा होना।",
        isreject: "अस्वीकार कर दिया",
        link_charge: "चेन पर रिचार्ज करें",
        select_network: "नेटवर्क चुनें",
        uncheck: "अनियंत्रित",
        unuseful: "अमान्य",
        wallet_address: "बटुआ पता"
    },
    forgot: {
        input_sms_code: "कृपया सत्यापन कोड दर्ज करें",
        reset: "पासवर्ड रीसेट",
        send: "भेजना",
        sms_code: "सत्यापन कोड"
    },
    func: {
        billing_detail: "बिलिंग विवरण",
        charge_coin: "जमा",
        invite_friend: "मित्रों को आमंत्रित करें",
        lang: "भाषा",
        real_auth: "वास्तविक नाम सत्यापन",
        reset_password: "पासवर्ड बदलें",
        setting: "स्थापना",
        wallet: "बटुआ",
        withdraw_coin: "निकासी"
    },
    order: {
        all_caution_money: "वर्तमान मार्जिन",
        balance: "संतुलन",
        bond_fee: "अंतर",
        buy_in: "खरीदना",
        calc_bond_fee: "अनुमानित मार्जिन",
        calc_handle_fee: "अनुमानित हैंडलिंग शुल्क",
        can_use: "पूर्वभुगतान उपलब्ध है",
        cancel: "आदेश रद्द",
        close: "बंद स्थिति",
        close_price: "सस्ते गोदाम मूल्य",
        confrim_close_order: "क्या पोजीशन बंद करनी है",
        currency: "लेन-देन जोड़े",
        current_price: "मौजूदा कीमत",
        dealing: "लंबित ऑर्डर",
        detail: "ऑर्डर का विवरण",
        hand_num: "बहुत",
        handle_fee: "संचालन शुल्क",
        hazard_rate: "जोखिम दर",
        history: "इतिहास",
        holding: "पद धारण",
        income: "लाभ",
        market_price: "बाजार कीमत",
        open_time: "खुलने का समय",
        order_id: "आरक्षण संख्या",
        order_price: "कम इकाई मूल्य",
        order_type: "दिशा",
        page: "बहुत",
        pay_rate: "पूर्वभुगतान अनुपात",
        profit: "लाभ और हानि",
        sell_out: "बेचना",
        set_stop_price: "बचाना",
        stop_loss: "हानि निर्धारित करें",
        stop_profit: "लाभ लेने के",
        times: "गुणक",
        total: "शुद्ध मूल्य",
        total_ol: "कुल लाभ और हानि",
        trade_amount: "बहुत",
        trading: "स्थितियां",
        un_deal_order: "लंबित ऑर्डर",
        unit: "प्रत्येक बहुत"
    },
    public: {
        tocust: "अब संपर्क",
        add: "जोड़ना",
        cancel: "रद्द करना",
        chat: "ऑनलाइन सेवा",
        coming_soon: "जल्द ही आ रहा है~",
        confirm: "इसकी सूचना देने वाला",
        confirm_del: "क्या वाकई हटाना है?",
        copy: "प्रतिलिपि",
        copy_success: "सफलतापूर्वक कॉपी किया गया",
        delete: "मिटाना",
        freeze: "जमाना",
        history: "अभिलेख",
        input_full: "कृपया पूर्ण करें",
        last_price: "अंतिम मूल्य",
        market: "रुझान",
        name: "नाम",
        no_data: "अभी तक कोई डेटा नहीं",
        no_more: "अब और नहीं",
        number: "मात्रा",
        other: "अन्य",
        password_not_equal: "पासवर्ड असंगति",
        product_list: "उत्पादों की सूची",
        quotation: "24H परिवर्तन",
        set: "तय करना",
        setting: "सेटिंग",
        status: "स्थिति",
        submit: "जमा करना",
        submit_order: "आदेश प्रस्तुत",
        tip: "तत्पर",
        upload_pic: "प्रमाणपत्र अपलोड करना",
        use: "उपलब्ध"
    },
    trade: {
        balance: "संतुलन",
        best_price: "सर्वोत्तम मौजूदा कीमत पर व्यापार",
        buy: "खरीदना",
        buy_low: "लघुकरण",
        buy_price: "कीमत",
        buy_up: "लंबे तक जाओ",
        close_order: "बंद स्थिति",
        closed: "बंद किया हुआ",
        current_entrust: "पूर्व कमीशनिंग",
        enter_price: "कृपया कीमत दर्ज करें",
        high: "उच्च",
        last_price: "बाजार कीमत",
        low: "कम",
        no_mult: "कोई एकाधिक नहीं",
        no_mult_config: "कोई लीवर कॉन्फ़िगर नहीं किया गया, अस्थायी रूप से ऑर्डर देने में असमर्थ",
        open: "खुला",
        operate: "संचालन",
        order: "आदेश",
        order_confirm: "आपके ऑर्डर की पुष्टि हो गई है",
        order_page: "ऑर्डर पेज",
        price: "कीमत",
        sell: "बेचना",
        time: "तारीख",
        trade_amount: "ट्रेडिंग लॉट का आकार",
        trade_price: "सोदा राशि",
        type: "प्रकार",
        volume: "कुल मात्रा"
    },
    user: {
        account_detail: "खाता विवरण",
        announ: "घोषणा",
        announ_text: "नीतिगत कारणों से, उत्तर कोरिया, इज़राइल, चीन, वानुअतु और क्यूबा को सेवाएँ प्रदान नहीं की जाती हैं।",
        area_code: "एरिया कोड",
        can_use_asset: "उपलब्ध संपत्ति",
        com_email: "शिकायत ईमेल",
        confirm_logout: "क्या रजिस्टर से हटना है",
        confirm_reset: "क्या इसे वापस करना है",
        copy_invite_link: "आमंत्रण लिंक कॉपी करें",
        dh: "टेलीफ़ोन",
        email: "ईमेल",
        forgot_password: "पासवर्ड भूल गए",
        h_accept: "स्वीकार करना",
        h_second: "दूसरा",
        h_timer: "उलटी गिनती",
        h_welc: "उपयोग में आपका स्वागत है",
        input_email: "कृपया अपना ईमेल एड्रेस इंटर करें",
        input_invite_code: "कृपया आमंत्रण कोड दर्ज करें",
        input_old_password: "कृपया अपना पुराना पासवर्ड दर्ज करें",
        input_password: "अपना पासवर्ड दर्ज करें",
        input_phone: "कृपया अपना सेल फ़ोन नंबर दर्ज करें",
        invite_code: "आमंत्रण संकेत",
        join_us: "अब शामिल हों",
        login: "लॉग इन करें",
        login_email: "अपना ईमेल दर्ज करें",
        login_phone: "मोबाइल लॉगिन",
        logout: "लॉग आउट",
        logout_success: "सफल निकास",
        mock_account: "सिमुलेशन खाता",
        mock_login: "खाता खोलने की कोई आवश्यकता नहीं है, अभी व्यापार करने के लिए एक डेमो खाता बनाएं!",
        new_paw: "नया पासवर्ड",
        new_user: "नए उपयोगकर्ता?",
        only_true_account: "कृपया वास्तविक हार्ड खाते में पंजीकरण करें या लॉग इन करें",
        password: "पासवर्ड",
        please_login: "कृपया लॉगिन करें",
        qh: "एरिया कोड",
        qh_dh: "क्षेत्र कोड/टेलीफोन",
        re_input_pass: "पासवर्ड दोबारा टाइप करें",
        register: "पंजीकरण करवाना",
        register_email: "अपना ईमेल दर्ज करें",
        register_phone: "मोबाइल पंजीकरण",
        reset_mock_account: "सिम्युलेटेड खाता लौटाएं",
        trade_account: "ट्रेडिंग खाते",
        useful_balance: "उपलब्ध कोष",
        user_center: "मेरा",
        welcome_login: "लॉगिन करने के लिए आपका स्वागत है",
        welcome_register: "पंजीकरण करने के लिए आपका स्वागत है"
    },
    wallet: {
        bind: "बंधन",
        bind_bank_card: "बैंक कार्ड बांधें",
        bind_coin_address: "डिजिटल मुद्रा पते को बाइंड करें",
        not_bind: "कृपया पहले एक संग्रहण खाता स्थापित करें",
        save: "संरक्षण"
    },
    withdraw: {
        amount: "मात्रा",
        calc_get: "यह उम्मीद है कि",
        no_pay_way: "फ़्रेंच मुद्रा खाते के लिए कोई भुगतान चैनल नहीं है।",
        record: "सिक्का निकासी रिकॉर्ड",
        select_withdraw_address: "कृपया कॉल करने के लिए एक पता चुनें",
        send_amount: "प्राप्तियों की संख्या",
        symbol: "मुद्रा",
        unit: "इकाई",
        withdraw_address: "सिक्के निकालने का पता",
        withdraw_coin: "निकासी जमा करें"
    }
}
obj.cs = {
    auth: {
        front: "přední",
        input_info: "Vyplňte prosím zprávu osobně",
        input_name: "zadejte své jméno",
        name: "název",
        upload_back: "Nahrajte zadní stranu pasu/občanského průkazu",
        upload_front: "Nahrajte přední stranu pasu/občanského průkazu",
        upload_idcard: "Nahrajte prosím přední a zadní stranu pasu/certifikátu",
        waiting: "pod kontrolou",
        reject: "menolak",
        auth_again: "Sijil semula"
    },
    bank: {
        SWIFT: "rychle",
        account: "Účet příjemce platby",
        address: "adresa banky",
        bank_name: "",
        coin_type: "Měna příjmu",
        name: "jméno banky",
        remark: "Poznámka",
        swift_text: "rychle",
        username: "Příjemce platby"
    },
    financial: {
        bank: "bankovní karta",
        bank_charge: "drátový převod",
        charge_amount: "Množství nabití",
        charge_coin: "dolít",
        charge_history: "Záznam o vkladu",
        charge_num: "Částka dobití",
        coin: "digitální měna",
        collect_coin_type: "Měna příjmu",
        copy_address: "Zkopírujte adresu",
        financial_records: "finanční záznamy",
        input_charge_amount: "Zadejte částku dobití",
        input_charge_num: "Zadejte částku dobití",
        isfinish: "dokončeno",
        isreject: "odmítl",
        link_charge: "Dobíjejte na řetězu",
        select_network: "Vyberte síť",
        uncheck: "Nezkontrolováno",
        unuseful: "neplatný",
        wallet_address: "adresa peněženky"
    },
    forgot: {
        input_sms_code: "zadejte prosím ověřovací kód",
        reset: "obnovit heslo",
        send: "dodat",
        sms_code: "Ověřovací kód"
    },
    func: {
        billing_detail: "Fakturační údaje",
        charge_coin: "Vklad",
        invite_friend: "pozvat přátele",
        lang: "Jazyk",
        real_auth: "Ověřeno",
        reset_password: "změnit heslo",
        setting: "založit",
        wallet: "peněženka",
        withdraw_coin: "Vybrat finanční prostředky"
    },
    order: {
        all_caution_money: "Aktuální marže",
        balance: "Zůstatek",
        bond_fee: "okraj",
        buy_in: "Koupit",
        calc_bond_fee: "Devizová marže",
        calc_handle_fee: "Dodatečný poplatek",
        can_use: "Možnost platby předem",
        cancel: "Zrušit objednávku",
        close: "Zavřená poloha",
        close_price: "Závěrečná cena",
        confrim_close_order: "Zda uzavřít pozici",
        currency: "obchodní pár",
        current_price: "aktuální cena",
        dealing: "čekající objednávka",
        detail: "Podrobnosti o objednávce",
        hand_num: "Velikost pozemku",
        handle_fee: "manipulační poplatek",
        hazard_rate: "míra rizika",
        history: "Dějiny",
        holding: "Pozice",
        income: "příjem",
        market_price: "obchodní cena",
        open_time: "Otevírací doba",
        order_id: "číslo objednávky",
        order_price: "Cena objednávky",
        order_type: "směr",
        page: "OTEVŘENO",
        pay_rate: "Poměr záloh",
        profit: "Zisk a ztráta",
        sell_out: "prodat",
        set_stop_price: "Upravte take profit a stop loss",
        stop_loss: "zastavit ztrátu",
        stop_profit: "Vezměte si zisk",
        times: "násobek",
        total: "čisté jmění",
        total_ol: "Celkový zisk a ztráta",
        trade_amount: "Koupit množství",
        trading: "Otevřete pozici",
        un_deal_order: "čekající objednávka",
        unit: "každý"
    },
    public: {
        tocust: "Kontaktujte ihned",
        add: "Přidat do",
        cancel: "zrušení",
        chat: "Kontaktujte zákaznický servis",
        coming_soon: "Těším se ~",
        confirm: "potvrdit",
        confirm_del: "potvrdit smazání?",
        copy: "kopírovat",
        copy_success: "Úspěšně zkopírováno",
        delete: "vymazat",
        freeze: "sedět",
        history: "Záznam",
        input_full: "Vyplňte prosím kompletní",
        last_price: "Nejnovější cena",
        market: "trend",
        name: "název",
        no_data: "Žádná data",
        no_more: "už ne",
        number: "Množství",
        other: "jiný",
        password_not_equal: "Záměna hesla",
        product_list: "Seznam produktů",
        quotation: "Změna nabídky",
        set: "Revidovat",
        setting: "založit",
        status: "Stát",
        submit: "Předložit",
        submit_order: "Objednat",
        upload_pic: "Nahrajte zdroje"
    },
    trade: {
        balance: "Zůstatek",
        buy: "Koupit",
        buy_low: "krátký",
        buy_price: "cena",
        buy_up: "Duoduo",
        close_order: "Zavřená poloha",
        closed: "Trh uzavřen",
        current_entrust: "Aktuální pořadí",
        enter_price: "Zadejte prosím cenu",
        high: "vysoký",
        last_price: "obchodní cena",
        low: "Nízký",
        no_mult: "Zatím žádné násobky",
        no_mult_config: "Pákový efekt není nakonfigurován a objednávky nelze dočasně zadávat.",
        open: "OTEVŘENO",
        operate: "fungovat",
        order: "Objednat",
        order_confirm: "Vaše objednávka byla potvrzena",
        order_page: "Stránka objednávky",
        price: "jednotková cena",
        sell: "prodat",
        time: "čas",
        trade_amount: "Obchodní losy",
        trade_price: "Objem transakcí",
        type: "typ",
        volume: "souhrn"
    },
    user: {
        account_detail: "Detaily účtu",
        announ: "oznámení",
        announ_text: "Z politických důvodů nejsou služby poskytovány Severní Koreji, Izraeli, Číně, Vanuatu a Kubě.",
        area_code: "plocha",
        can_use_asset: "Dostupná aktiva",
        com_email: "Reklamační email",
        confirm_logout: "Zda se odhlásit",
        confirm_reset: "Resetovat nebo ne",
        copy_invite_link: "Zkopírujte odkaz na pozvánku",
        dh: "Telefon",
        email: "Pošta",
        forgot_password: "zapomenout heslo",
        h_accept: "akceptovat",
        h_second: "Druhý",
        h_timer: "Odpočítávání",
        h_welc: "Vítejte",
        input_email: "Zadejte prosím svůj email",
        input_invite_code: "Zadejte prosím kód pozvánky",
        input_old_password: "Zadejte staré heslo",
        input_password: "Prosím vložte heslo",
        input_phone: "zadejte prosím své telefonní číslo",
        invite_code: "Kód pozvánky",
        join_us: "Přidej se teď",
        login: "Přihlásit se",
        login_email: "Přihlášení e-mailem",
        login_phone: "Přihlaste se pomocí telefonu",
        logout: "odhlásit se",
        logout_success: "úspěšně ukončit",
        mock_account: "Demo účet",
        mock_login: "Není třeba zakládat účet, vytvořit demo účet a začít obchodovat hned!",
        new_paw: "nové heslo",
        new_user: "nový uživatel?",
        only_true_account: "Zaregistrujte se nebo se přihlaste ke skutečnému účtu",
        password: "Heslo",
        please_login: "prosím přihlašte se",
        qh: "plocha",
        qh_dh: "Předčíslí/telefon",
        re_input_pass: "Zadejte heslo znovu",
        register: "Registrovat",
        register_email: "emailová registrace",
        register_phone: "Zaregistrujte svůj telefon",
        reset_mock_account: "Resetovat demo účet",
        trade_account: "transakční účet",
        useful_balance: "Dostupné prostředky",
        user_center: "Osobní centrum",
        welcome_login: "Přihlaste se prosím",
        welcome_register: "Vítejte v registraci"
    },
    wallet: {
        bind: "vazba",
        bind_bank_card: "Svažte bankovní kartu",
        bind_coin_address: "Svažte adresu digitální měny",
        not_bind: "Nejprve si prosím nastavte platební účet",
        save: "Uložit"
    },
    withdraw: {
        amount: "Množství",
        calc_get: "Odhadované množství při příjezdu",
        no_pay_way: "Pro tento účet v legální měně v současné době neexistuje žádný platební kanál",
        record: "Záznam o výběru mincí",
        select_withdraw_address: "Vyberte adresu měny",
        send_amount: "Příjezdové množství",
        symbol: "měna",
        unit: "jednotka",
        withdraw_address: "Adresa pro výběr",
        withdraw_coin: "Vytáhněte mince"
    }
}
export default obj;

